import {initializeApp} from 'firebase/app';
import {getFirestore } from 'firebase/firestore';
import {firebaseConfig} from "./firebaseConfig";
import { doc, getDoc, setDoc} from 'firebase/firestore';
import { getToken, Logout } from '../authMethods';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export const getUsersFirebase =async()=>{
    try{
        const token = getToken()
        const docRef = doc(db, "users", token);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log('docSnap',docSnap.data())
            return docSnap.data();
        } else {
         // doc.data() will be undefined in this case
            console.log("No such document!");
            return null
        }
    }catch(err){
        console.log(err)
        if(err.code ==='permission-denied' ){Logout()}
        return null
    }
}

export const setUserFirebase =async(userData)=>{
    const uid = getToken()
    const info = {...userData, uid: uid}
    //console.log('setUserFirebase userData',info)
    const docRef = doc(db, "users", uid);
    await setDoc(docRef, info);
}