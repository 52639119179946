import React from "react";
import BackToProfile from "./BackToProfile";
import  {ReactComponent as DiscountSvg} from "../../Components/svg/discount.svg"
import {motion} from 'framer-motion'
//import Waiting from "../../Components/UI/Waiting";

const DiscountPage =({discount})=>{
    const pageName ='Знижки'
    console.log('discount',discount)
    return(
        <motion.div className="info personInfo"
        initial ={{scale:0.6}}
        animate={{scale:1}}
        exit ={{opacity:0}}>
            <BackToProfile pageName={pageName}/>
            {discount ?<DiscountValue discount={discount} /> : <DiscountNone/>}
        </motion.div>
    )
}

const DiscountValue =({discount})=>{
    const currentDisc = discount.discount
    const list = discount.list 
    return(
        <div className="discounts">
            <div>
                <div className="current">
                    <div>
                      <span><b>{getValue(currentDisc)}</b>%</span>
                     </div>
                 </div>
             <ul className="frame frame_bt">
                {list.map(item=><ListItem item={item} key={item.id}/>)}
            </ul>
        </div>
    </div>
    )
}
const ListItem =({item})=>{
    return( <li>
        <div className="discount_info">
            <div className="value">
                <div><span>{getValue(item)} %</span></div>
            </div>
            <div className="condition">
                <div className="qnt">
                    <div>Залишилось</div>
                    <div><b><span>{getCount(item)}</span> знижок </b></div>
                </div>
                <div className="date">
                    <div>
                        <svg viewBox="0 0 510.992 510.992" xmlns="http://www.w3.org/2000/svg">
                            <path d="M151.121 129.051c-8.837 0-16-7.163-16-16V16c0-8.837 7.163-16 16-16s16 7.163 16 16v97.051c0 8.837-7.163 16-16 16zM359.872 129.051c-8.837 0-16-7.163-16-16V16c0-8.837 7.163-16 16-16s16 7.163 16 16v97.051c0 8.837-7.163 16-16 16zM24.263 221.975v220.767c0 37.634 30.617 68.251 68.251 68.251h325.965c37.634 0 68.251-30.617 68.251-68.251V221.975z"></path>
                            <path d="M486.729 189.975v-73.198c0-37.634-30.617-68.251-68.251-68.251h-10.606v64.525c0 26.468-21.532 48-48 48s-48-21.532-48-48V48.525H199.121v64.525c0 26.468-21.532 48-48 48s-48-21.532-48-48V48.525H92.514c-37.634 0-68.251 30.617-68.251 68.251v73.198h462.466z"></path>
                        </svg>
                    </div>
                    <div>{dateEpired(item)}</div>
                </div>
            </div>
        </div>
        <div className="decor left"></div>
        <div className="decor right"></div>
    </li>)
}
const getValue =(d)=> d?.value ? Math.round( d?.value*100) : 0
const getCount =(d)=> d.count_left ? d.count_left : 0
const dateEpired =(d)=>d.datetime_end ? d.datetime_end.split('T')[0] : ''


const DiscountNone =()=>{
    return(
        <div className="non_discount">
        <div className="icon">
        <DiscountSvg/>
            <div>
                %
            </div>
        </div>
        <div className="text">
            <h1>У вас поки що немає знижок</h1>
            <p>
                На жаль, зараз немає активних знижок, ми повідомимо Вам, як тільки вони з'являться.<br/><br/> Слідкуйте за новинами в наших соціальних мережах і на нашому офіційному сайті <a href="https://book4sport.com">book4sport.com</a>
            </p>
        </div>
    </div>
    )
}
export default DiscountPage