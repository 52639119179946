import '../../css/menu.css'
import React from 'react';
import { NavLink} from "react-router-dom";
import LangChange from '../Lang/LangChange';
//import { useTranslation } from 'react-i18next';
import {ReactComponent as LogoSvg} from '../svg/logo.svg'

const Menu = ({dropFilter}) => {
    //const { t } = useTranslation();
    const menu =[
        {
            link:'/search', 
            name:'Пошук',
            svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M225.474 0C101.151 0 0 101.151 0 225.474c0 124.33 101.151 225.474 225.474 225.474 124.33 0 225.474-101.144 225.474-225.474C450.948 101.151 349.804 0 225.474 0zm0 409.323c-101.373 0-183.848-82.475-183.848-183.848S124.101 41.626 225.474 41.626s183.848 82.475 183.848 183.848-82.475 183.849-183.848 183.849z"></path><path d="M505.902 476.472L386.574 357.144c-8.131-8.131-21.299-8.131-29.43 0-8.131 8.124-8.131 21.306 0 29.43l119.328 119.328A20.74 20.74 0 00491.187 512a20.754 20.754 0 0014.715-6.098c8.131-8.124 8.131-21.306 0-29.43z"></path></svg>
        },
        {
            link:'/myclub', 
            name:'Мої клуби',
            svg: <svg viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 455.516c-7.29 0-14.316-2.641-19.793-7.438-20.684-18.086-40.625-35.082-58.219-50.074l-.09-.078c-51.582-43.957-96.125-81.918-127.117-119.313C16.137 236.81 0 197.172 0 153.871c0-42.07 14.426-80.883 40.617-109.293C67.121 15.832 103.488 0 143.031 0c29.555 0 56.621 9.344 80.446 27.77C235.5 37.07 246.398 48.453 256 61.73c9.605-13.277 20.5-24.66 32.527-33.96C312.352 9.344 339.418 0 368.973 0c39.539 0 75.91 15.832 102.414 44.578C497.578 72.988 512 111.801 512 153.871c0 43.3-16.133 82.938-50.777 124.738-30.993 37.399-75.532 75.356-127.106 119.309-17.625 15.016-37.597 32.039-58.328 50.168a30.046 30.046 0 01-19.789 7.43zM143.031 29.992c-31.066 0-59.605 12.399-80.367 34.914-21.07 22.856-32.676 54.45-32.676 88.965 0 36.418 13.535 68.988 43.883 105.606 29.332 35.394 72.961 72.574 123.477 115.625l.093.078c17.66 15.05 37.68 32.113 58.516 50.332 20.961-18.254 41.012-35.344 58.707-50.418 50.512-43.051 94.137-80.223 123.469-115.617 30.344-36.618 43.879-69.188 43.879-105.606 0-34.516-11.606-66.11-32.676-88.965-20.758-22.515-49.3-34.914-80.363-34.914-22.758 0-43.653 7.235-62.102 21.5-16.441 12.719-27.894 28.797-34.61 40.047-3.452 5.785-9.53 9.238-16.261 9.238s-12.809-3.453-16.262-9.238c-6.71-11.25-18.164-27.328-34.61-40.047-18.448-14.265-39.343-21.5-62.097-21.5zm0 0"></path></svg>
        },
        {
            link:'/action', 
            name:'Акції',
            svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M506.968 36.279a21.769 21.769 0 00-.075-1.25c-1.545-16.924-15.015-29.69-30.852-29.69h-.013L273.113.314a18.86 18.86 0 00-13.789 5.516L9.291 255.855C3.298 261.848 0 269.701 0 277.975c0 8.28 3.298 16.133 9.298 22.126l202.287 202.287v.006c6.201 6.201 14.166 9.298 22.126 9.298 7.947 0 15.894-3.091 22.076-9.266l250.66-249.404a18.831 18.831 0 005.547-13.827l-5.026-202.916zM238.221 475.738l-.019-.025c.006.006.013.006.019.019v.006zm-4.51-4.523L40.476 277.981 280.262 38.189l189.17 4.687 4.68 189.145-240.401 239.194z"></path><path d="M345.521 93.912c-39.836 0-72.245 32.41-72.245 72.245 0 39.835 32.41 72.245 72.245 72.245 39.835 0 72.245-32.41 72.245-72.245.001-39.835-32.409-72.245-72.245-72.245zm0 106.798c-19.048 0-34.552-15.498-34.552-34.552 0-19.054 15.505-34.552 34.552-34.552s34.552 15.498 34.552 34.552c.001 19.053-15.504 34.552-34.552 34.552z"></path></svg>
        },
        {
            link:'/orders', 
            name:'Бронювання',
            svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M497.36 69.995c-7.532-7.545-19.753-7.558-27.285-.032L238.582 300.845l-83.522-90.713c-7.217-7.834-19.419-8.342-27.266-1.126-7.841 7.217-8.343 19.425-1.126 27.266l97.126 105.481a19.273 19.273 0 0013.784 6.22c.141.006.277.006.412.006a19.317 19.317 0 0013.623-5.628L497.322 97.286c7.551-7.525 7.564-19.746.038-27.291z"></path><path d="M492.703 236.703c-10.658 0-19.296 8.638-19.296 19.297 0 119.883-97.524 217.407-217.407 217.407-119.876 0-217.407-97.524-217.407-217.407 0-119.876 97.531-217.407 217.407-217.407 10.658 0 19.297-8.638 19.297-19.296C275.297 8.638 266.658 0 256 0 114.84 0 0 114.84 0 256c0 141.154 114.84 256 256 256 141.154 0 256-114.846 256-256 0-10.658-8.638-19.297-19.297-19.297z"></path></svg>
        },
        {
            link:'/profile', 
            name:'Профіль',
            svg: <svg viewBox="0 0 551.13 551.13" xmlns="http://www.w3.org/2000/svg"><path d="M275.565 275.565c-75.972 0-137.783-61.81-137.783-137.783S199.593 0 275.565 0s137.783 61.81 137.783 137.783-61.811 137.782-137.783 137.782zm0-241.119c-56.983 0-103.337 46.353-103.337 103.337S218.581 241.12 275.565 241.12s103.337-46.354 103.337-103.337S332.548 34.446 275.565 34.446zM499.461 551.13H51.668c-9.52 0-17.223-7.703-17.223-17.223V415.349c0-17.795 9.099-34.513 23.732-43.663 129.339-80.682 305.554-80.665 434.759-.017 14.649 9.166 23.749 25.885 23.749 43.679v118.558c0 9.521-7.703 17.224-17.224 17.224zm-430.57-34.445h413.348V415.349c0-6.004-2.893-11.555-7.552-14.464-118.256-73.819-279.905-73.87-398.26.017-4.642 2.893-7.535 8.443-7.535 14.448z"></path></svg>
        }
    ]
    return (
        <header className='bottom_menu'>
            <NavLink className="logo" to="/">
                <LogoSvg/>
            </NavLink>
            <nav className='nav'>
            <ul>
               {menu.map(m=>{
                return (
                    <li  key={m.name}>
                        <NavLink to={m.link} onClick={dropFilter} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                        <div className = "icon">{m.svg}</div>
                        <div className="text">{m.name}</div>
                        </NavLink>
                    </li>
                )
               })}
            </ul>
            </nav>
            <div style={{display:'none'}}><LangChange/></div>
        </header>
    );
};

export default Menu;