import '../../css/phone_login.css';
import React, { useState } from 'react';
import {Routes, Route, useLocation } from "react-router-dom";
import AuthInfoConfirm from './AuthInfoConfirm';
import PhoneEnter from './PhoneEnter';
import SmsVerification from './SmsVerification'

const Login =()=>{
    
    const location = useLocation()
    const [loc] = useState(location.pathname)
                     

    return(
    <section className="registration content_blocks">
     <Routes>
        <Route path="*" element={<PhoneEnter loc={loc}/>}/>
        <Route path="/sms" element={<SmsVerification loc={loc}/>}/>
        <Route path="/info" element={<AuthInfoConfirm loc={loc}/>}/>
     </Routes> 
     </section>
    )
}

export default Login;