import React, {useState,useEffect, useContext} from 'react';
import {getUserInfoAuth, postUserInfoAuth} from '../../hooks/GetUserInfo'
import { AuthContext } from '../../context';
import {Link, useNavigate} from "react-router-dom";
import FormSubmitBottom from '../../Components/UI/FormSubmitBottom'
import {motion} from 'framer-motion'

//import {Link, useNavigate} from "react-router-dom";

const AuthInfoConfirm =({loc})=>{
    const [load, setLoad] = useState(false)
    const [sex, setSex] = useState('male')
    const navigate = useNavigate();
    const {user, setUser} = useContext(AuthContext)
    console.log('user', user)
    const [userInfo, setUserInfo] = useState()
    const fetchUserData =async()=>{
        setLoad(true)
        const responce= await getUserInfoAuth()
        responce?.info?.userName ? (()=>{
            navigate('/search')
            setUser(true)})()
            : setUserInfo(responce)
            
        setLoad(false)
    }
    useEffect(()=>{
        fetchUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
   console.log('userInfo',userInfo)
   const onSubmit =async (event)=>{
        setLoad(true)
        event.preventDefault();
        const info ={
            userName : event.target.user_name.value,
            city : event.target.city.value,
            gender : sex
        } 
        const result  = await postUserInfoAuth(info)
        const checkSave =(result)=> result ? 
            (()=>{ 
                navigate('/search')
                setUser(true) 
            })()
            : null
        checkSave(result)
        setLoad(false)
   } 
   return(
        <motion.section id="personal_info"
            initial ={{scale:0.6}}
            animate={{scale:1}}
            exit ={{opacity:0}}
        >
             <Link to ="/">
                <div className="back" id="sms_back">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823"><path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"></path></svg>
                </div>
            </Link>
            <div className="frame">
                <form onSubmit={onSubmit} id="add_personal_info">
                    <h1>Доповніть свій профайл</h1>
                    <UserName userInfo={userInfo}/>
                    <UserSex sex={sex} setSex={setSex}/>
                    <UserCity userInfo={userInfo}/>
                    <UserAgree/>
                    <p className="err_msg"><span>Перевірте правильність заповнення імені</span></p>
                    <FormSubmitBottom load={load}/>
                </form>
            </div>
        </motion.section>
    )
}

const UserName =({userInfo})=>{
    const name =userInfo?.info?.userName ? userInfo.info.userName : ''
    return(
        <div className="user_name">
            <div>Введіть ім'я</div>
            <div className="name_txt input_container">
                <input type="text" name ="user_name" id="user_name" required defaultValue={name}/>
            </div>
        </div>
    )
}

const UserSex =({sex, setSex})=>{
    const gender = sex === 'male' ? 'sex' : 'sex female' 
    return(
        <div className={gender}>
            <span id="sex_chose"></span>
            <div id="male" onClick={()=>setSex('male')}><div>Чоловік</div></div>
            <div id="female" onClick={()=>setSex('female')}><div>Жінка</div></div>
        </div>
    )
}

const UserCity =({userInfo})=>{
    const cities =userInfo?.cityList ? userInfo.cityList : [] 
    console.log('cities', userInfo)
    return(
        <div className="user_name">
            <div>Вкажіть місто</div>
            <div className="choseCity">
                <select name="city">
                    <option value="Another">Інше місце</option>
                    {cities.map(city => <CityList city={city} key={city.id}/>)}
                </select>
            </div>
        </div>
    )
}

const CityList=({city})=>{
    return(
        <option value={city.nameUkr}>{city.text}</option>
    )
}

const UserAgree =()=>{
    return(
        <div className="user_condition">
            <input type="checkbox" required style={{marginRight: '10px'}} />
            <div>Підтверджуючи ви автоматично даєте згоду на обробку ваших персональних даних</div>
        </div>
    )
}


export default AuthInfoConfirm