import React from 'react';
import Parser from 'html-react-parser';

const ItemFilterIcon = ({c}) =>{
    return (
        <div className="filter_icon">
            <div className="icon"> 
                {Parser(c.icon)}
            </div>
            <div className="text gray_txt">{c.text}</div>
        </div>
    )
}
const ItemFilter =({c,i})=>{
    return <span>{i>0 ? <i>•</i>: ''} {c.text}</span>
}
const CortFilterList = ({list, className}) => {
    return (
        <div className={className}>
            {list.map((c,i)=> className === 'value'
                ? <ItemFilterIcon key={c.name + ''+i} c={c}/> 
                : <ItemFilter key={c.nam+''+i} c={c} i={i}/>)
            }
        </div>
    );
};


export default CortFilterList;