import "../../css/action.css";
import React from 'react';
import { useState, useEffect } from 'react';
import GetService from '../../API/getService';
import {motion} from 'framer-motion'
import {func_dateToString} from '../../js/function'
import Waiting from '../../Components/UI/Waiting'

const Action = () => {
    const [actions, setActions] = useState([])
    const [load, setLoad] = useState(true)
    const fetchClubs = async() => {
        setLoad(true)
        const responce = await GetService.getActions()
        if(responce) setActions(responce) 
        setLoad(false)
    }
    useEffect(()=>{
        fetchClubs()
    }, [])
   
    console.log('Actions', actions)
    return (
        !load ?
            <section className="contentWrapper">
            <motion.main className='action'
                initial ={{scale:0.6}}
                animate={{scale:1}}
                exit ={{opacity:0}}
            >
                <h1>Акції</h1>
                <div className='list'>
                    {actions.map(action=><ActionItem key={'id_'+action.id} action={action}/>)}
                </div>				
            </motion.main>
            </section>
        : <Waiting/>
    );
};

const ActionItem =({action})=>{
    const {d_m_y} = func_dateToString()
    const dateEnd = d_m_y(new Date(action.dateEnd))
    return(
        <div className="action_item frame frame_bt">
            <div className="img_container">
                <div className="img"> 
                    <img src={action.imageUrl} alt="Тенисный клуб промо"/>
                 </div>
            </div>
            <h2 className="title">{action.original_text}</h2>
            <div className="action_info">
                <p>{action.description}</p><br/>
                <div><i>до {dateEnd}</i></div>
            </div>
        </div>
    )
}

export default Action;