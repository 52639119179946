import {getUsersFirebase, setUserFirebase} from '../firebase/firebaseAPI'
import GetService from '../API/getService'
import {setObjOnTemplate} from '../js/function'

const userInfo ={
    phoneNumber :"",
    photoUrl : "",
    uid :"",
    userName :"",
    birthday :"",
    city : "",
    email : "",
    gender : "",
    notification_email : false,
    notification_email_advertising :false,
    notification_email_news : false,
    notification_email_partnerNews : false,
    notification_push : false,
    notification_sms : false
}
export const getUserInfo =async()=>{
    const userDataBase = await GetService.getClientInfo()
    const userPayments = await GetService.getPaymentList()
    const userCityList = await GetService.getCityList()
    const userInfoPhone = userDataBase?.phone ? {...userInfo, phoneNumber: userDataBase?.phone } : userInfo
    const fillUserData = compareObj(userInfoPhone)
    const userFirebase = await getUsersFirebase()
   
    const discount = userDataBase ? userDataBase.discount 
        ? {discount:userDataBase.discount, list: userDataBase.discount_list}
        : null: null
    return userFirebase 
            ? userDataBase 
            ? {info:fillUserData(userFirebase), discount: discount, payments:userPayments, cityList:userCityList}
            : {info:{...userInfoPhone}, discount: discount, payments:userPayments, cityList:userCityList}
            : {info:{...userInfoPhone}, discount: discount, payments:userPayments, cityList:userCityList}
}
export const getUserInfoAuth =async()=>{
    const fillUserData = compareObj(userInfo)
    const userFirebase = await getUsersFirebase()
    //console.log('fillUserData', fillUserData(userFirebase))
    const userCityList = await GetService.getCityList()
    return userFirebase ? {info:fillUserData(userFirebase), cityList:userCityList} : {info:null, cityList:userCityList}
}

export const postUserInfoAuth=async (info)=>{
    const fillUserData = compareObj(userInfo)
    const userData = fillUserData(info)
    await setUserFirebase(userData)
    return await getUserInfoAuth()
}

const compareObj =userInfo=> userFirebase=>setObjOnTemplate(userInfo, userFirebase)



    
