import React from 'react';
import {useOrderRecalc} from '../../hooks/useOrderRecalc';
import {useNavigate, Link, useLocation} from 'react-router-dom';
import PostService from '../../API/postService';
import GetService from '../../API/getService';
import {getCurentPath} from '../../js/function';
import { useSelector } from 'react-redux';

const navPages = {
    time:{location: 'time', back:'../', forward:'./addService'},
    addService:{location: 'addService', back:'../time', forward:'./confirm'},
    confirm:{location: 'confirm', back:'../addService', forward:null}
}
export const Continue =({club, setOrderError})=>{
    const location = useLocation()
    const currentPath = getCurentPath(location.pathname)
    const url =navPages?.[currentPath] 
    return url? <OrderPanal url={url.forward} setOrderError={setOrderError}/>: <СontinueButton club={club}/>
}

const СontinueButton =({club})=>{
    const avalible =()=>{
        return (
            <Link to={'./time'} className="confirmButton"> <span>Далі</span></Link>
        )
    }
    const notAvalible =()=>{
        return(
             <div className="confirmButton" style={{background: 'rgb(97, 97, 97)'}}>			
             <span>Замовлення неможливе</span>
             </div>
        )
    }
    return club.main_service ? avalible() : notAvalible() 
}

const OrderPanal = ({url, setOrderError}) => {
    const order = useOrderRecalc()
    useSelector(state => state.order);
    return (
        <section className="confirm_order">
            <div className="cost">
                <OrderSum order={order}/>
            </div>
           <OrderProceed order={order} url={url} setOrderError={setOrderError}/>
        </section>
    );
};

const OrderSum =({order})=>{
    const getClass =()=> order.discount?.value>0 ? 'orderSum discount' : 'orderSum'
    const discount =()=> order.discountCalc ? order.discountCalc : ''
    const opacity =()=> order.price > 0 ? 1 : 0
    return(
        <div className={getClass()} style={{opacity:opacity()}}>
            <div>Разом:</div>
            <div>
                <div><b><s>{order.price} ₴</s></b></div>
                <div>
                    <b><span className="order_sum">{order.amount} ₴</span></b>
                </div>
                <div>Знижка {discount()}%</div>
            </div>
        </div>
    )
}

const OrderProceed =({order, url, setOrderError})=>{
    let navigate = useNavigate();
    const onClick =async ()=> {
       const responce = await PostService.postOrder(order)
       console.log('postOrder rescponce', responce)
       const getLink = async(res)=>{
            const order_id = res ? res?.client_order_id : null
            const responce=   order_id ? await GetService.getPaymentLink(order_id) : null
            return responce ? responce?.linkFondy ? responce?.linkFondy : null : null
       }
       const linck = await getLink(responce)
       const proceed =()=>  linck ? window.location.href = linck : setOrderError(true)
       proceed()
       console.log('postOrder linck', linck)
    }
    const checkTime =()=> order?.time?.timeEnd ? (order?.time?.timeEnd - order?.time?.timeStart >= 1)
            ? url ? proceed(url) : confirm() : wait() : wait()
    const wait =()=>{
        return(
            <div className="no_sum">
                <div style={{fontSize:'12px'}}>Оберить час оренди корту<br/>
                    <span style={{fontSize:'10px'}}>Мінімальний час бронювання корту - 1 година</span>
                </div>
            </div>
        )
    }
    const proceed =(url)=>{
        return(
            <div onClick={()=>navigate(url)} className="btn_g order_proceed time_order"> <span>Далі</span></div>
        )
    }
    const confirm =()=>{
        return(
            <div className="btn_g order_proceed time_order" onClick={onClick}><span>Далі</span></div>
        )
    }
    return checkTime()
}
export default OrderPanal;