import React, {useState, useEffect} from 'react';
import {Routes, Route} from "react-router-dom";
import Profile from './Profile';
import DiscountPage from './DiscountPage';
import {getUserInfo} from '../../hooks/GetUserInfo'
import Waiting from '../../Components/UI/Waiting'
import MsgInfo from './MsgInfo';
import Payment from './Payment';
import UserHelp from './UserHelp'
import UserData from './UserData';
import Conditions from './Conditions';
import Document from './Document';


const RootProfile = () => {
    const [load, setLoad] = useState(false)
    const [userInfo, setUserInfo] = useState(null)
    const checkUserInfo = (info)=> info ? setUserInfo(info): setUserInfo(null)
    const checkLoad = (info)=> info ?  setLoad(true): setLoad(false)
    const changeInfo =(userInfo)=> setUserInfo({...userInfo})
    const fetchUserData =async()=>{
        setLoad(false)
        const info = await getUserInfo()
        console.log('userInfo', info)
        checkUserInfo(info)
        checkLoad(info)
    }
    useEffect(()=>{
        fetchUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        load ? <PrintProfiles userInfo={userInfo} changeInfo={changeInfo}/> : <Waiting/>
    )
   

};
const PrintProfiles =({userInfo, changeInfo})=>{
    const documents =[
        {
            url:    "condition",
            name:	"Угода користувача",
            iframe: "https://docs.google.com/document/d/12QLMexu-z2THM1293ARbPAJrNiZ8XHC3c76bFqOlIAM/edit?usp=sharing"
        },
        {
            url:    "confendetional",
            name: 	"Політика конфіденційності",
            iframe: "https://docs.google.com/document/d/1X9EPYAAQhYx182X6zYUCLmmJOXxi1_D7vhti-fNFfso/edit?usp=sharing"
        },
        {
            url:    "coocki",
            name: 	"Політика Cookie",
            iframe: "https://docs.google.com/document/d/1yMDQ9Sbjhi2CqxBIIeGbEJCd7U8h0mPUoUIsy-ziKy8/edit?usp=sharing"
        },
        {
            url:    "refund",
            name: 	"Політика повернення коштів",
            iframe: "https://docs.google.com/document/d/19mmTIkuC0QXxWtIkFMDBh22OCzj3lPH3D6MzL_rbfBk/edit?usp=sharing"
        },
        {
            url:    "payments",
            name: 	"Способи оплати",
            iframe: "https://docs.google.com/document/d/10_m26BKL9uskP2DuyxOU_i8wUN2NPRGMAxwfMhnx2-8/edit?usp=sharing"
        },
        {
            url:     "cancelOrder",
            name: 	"Правила скасування бронювання",
            iframe: "https://docs.google.com/document/d/1-UTGpz4d6hvoOlVqOsbYI2TUWVtzBu4-biCMgoyL6yM/edit?usp=sharing"
        }
    ]
    return(
        <section className="contentWrapper">
        <Routes>
            <Route path="/" element={<Profile userInfo={userInfo}/>}/>
            <Route path="discount" element={<DiscountPage discount={userInfo?.discount}/>}/>
            <Route path="msg" element={<MsgInfo userInfo={userInfo} changeInfo={changeInfo}/>}/>
            <Route path="payments" element={<Payment payments={userInfo.payments}/>}/>
            <Route path='help' element={<UserHelp/>}/>
            <Route path='user_data' element={<UserData userInfo={userInfo}/>}/>
            <Route path='agreements' element={<Conditions documents={documents}/>}/>
            {documents.map(doc=>{
                return <Route key={doc.url} path={"agreements/" + doc.url} element = {<Document name={doc.name} iframe={doc.iframe}/>}/>
            }) }
        </Routes> 
        </section>
    )
}
export default RootProfile;


	