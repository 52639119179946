export function func_round(value){
    return value ? value > 0 ? Math.round(value) : '' : ''
}
export function func_dateToString(){
    const getYMD =(date)=>{
        try{
            const y = date.getFullYear()
            const m = (date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0'+(date.getMonth()+1)*1
            const d = date.getDate()>9 ? date.getDate() : '0'+date.getDate()
            return[y,m,d]
        }catch(err){
            console.log('func_dateToString err', err)
            return [null, null, null]
        }
    }
    const y_m_d = (date) =>{
        const[y,m,d] = getYMD(date)
        return y ? y + '-'+ m + '-' + d : null
    }
    const d_m_y = (date) => {
        const[y,m,d]=getYMD(date)
        return y ? d + ' - '+ m + ' - ' + y :null
    }
    return {y_m_d, d_m_y}
}
export function func_getTimeString(number){
    const getRest = () => Math.round((number - Math.floor(number))*60)
    const getMinute =()=>  getRest() > 9 
                         ? getRest() < 60 ? (getRest() + '') : '59' 
                         : ('0'+ getRest())
    const getTimeString = () => getRest() === 0 
                ? Math.floor(number) +':00' 
                : (Math.floor(number) +':'+ getMinute())
    return  typeof(number)==='number' ? getTimeString() : null
}
export function func_getDateNumber(string){
  try{
    return string.split('-')[0] + string.split('-')[1] + string.split('-')[2]
  }catch(err){
    return null
  }
}

export function func_checkBirthDate(string){
    try{
        const arr = string ? string.split(' ')[0].split('/') : ''
        return arr ? arr.join('.') : ''
    }catch(err){
        console.log('func_checkBirthDate err', err)
        return ''
    }
}

export const compose = (...fns) => (arg) =>  fns.reduce((composed, f) => f(composed),arg)

//Compose async functions
const compose2 = (f, g) => async (...args) => f(await g(...args))
export const composeAsync = (...fns) => fns.reduceRight(compose2)

export function curry(fn, ...args) {
    return (..._arg) => {
        return fn(...args, ..._arg);
    }
 }

 export const setObjOnTemplate =(temp, obj)=>{
    try{
        const result ={}
        const setResult =()=>{
            for(let key in temp){
                obj[key] ? result[key]= obj[key] : result[key] = temp[key]
            }
            return result
        }
        return typeof temp ==='object' ? typeof obj === 'object' ? setResult() : temp : null
    }catch(err){
        console.log('functions setObjOnTemplate', err)
        return null
    }
 }

 export const getUpperLocation =(loc)=>{
    try{
        const arr = loc.split('/')
        const newArr = arr[arr.length-1] ==='' ? arr.filter((item, i)=> i < (arr.length -1)): arr
        return (newArr.filter((item, i)=> i < (newArr.length -1)).join('/') +'/')
    }catch(err){
        return ''
    }
 }

 export const getCurentPath =(loc)=>{
    try{
        const arr = loc.split('/')
        return arr[arr.length-1] ==='' ? arr[arr.length -2]: arr[arr.length -1]
    }catch(err){
        return ''
    }
 }