import React from 'react';

const TimeCell = ({t, time, getOrderTime}) => {
    const cells = t.sub
   
    return (
        <li >
            <p><span>{t.price}</span></p>
           {cells.map((cell, i)=> <TimeSubCell key={'id_'+i} cell={cell} i={i} time={time} getOrderTime={getOrderTime}/>)}
        </li>
    );
};

const TimeSubCell =({cell, i, time, getOrderTime})=>{
    const classHalf =()=> i>0 ? 'half' : ''
    const inactive =()=> cell.available ? 'interval' : 'interval inactive'
    const checked =()=> cell.order ? 'checked' : ''
    const cellClick =({e, cell, time})=> {
        const errCell =() => {
            e.target.style.backgroundColor = 'rgba(255,0,0,0.1)'
            setTimeout(() => {
                e.target.style.removeProperty('background-color')
            }, 250);
            return null
        }
        const errChose =()=>{
            errCell()
            const ul = e.target.parentElement.parentElement
            ul.classList.add('errorChose')
            setTimeout(() => {
               ul.classList.remove('errorChose')
            }, 250);
            return null
        }
       
        const inactive =() => [...e.target.classList].includes('inactive')
        //console.log('cellClick', inactive())
        return inactive() ? errCell() : getOrderTime({cell:cell, time:time}) ? true : errChose()
    }
    return(
    <div className={inactive()+' '+ checked()} onClick={(e)=>cellClick({e:e, cell:cell, time:time})}>
        <span className= {classHalf()}><i>{cell.time_start}</i></span>
        <div></div><i>{cell.price}</i>
    </div>
    )
}

export default TimeCell;