import React from 'react';

const FilterTimeChoice = ({newFilter, setStart, setEnd}) => {
    const getTimeIntervals =()=>{
        const ts = Math.round(newFilter.default.time_start)
        const te = Math.round(newFilter.default.time_end)
        const length =(interval)=> interval>0 ? interval : 1
        const getTimeStart = ()=>{
           const interval =()=> newFilter.time.time_end - ts
           return Array.from({length:length(interval())}, (a,i)=>ts+i)
        }
        const getTimeEnd = ()=>{
            const interval =()=>  te - newFilter.time.time_start
            return Array.from({length:length(interval())}, (a,i)=>te-i)
         }
        return {getTimeStart, getTimeEnd}
    }
    const {getTimeStart, getTimeEnd} = getTimeIntervals()
    const timeStart =getTimeStart()
    const timeEnd =getTimeEnd()
    const setTimeStart =(e) => setStart(e.target.value*1)
    const setTimeEnd =(e)=> setEnd(e.target.value*1)

    return (
    <div className="filter_area">
        <div>
            <div className="filter_content" id="filterContentArea">
                <div className="innerMsg">Оберить діапазон часу</div>
                <div className="timeSelection">
                    <div>
                        <span>Від</span> 
                        <select name="start" onChange={setTimeStart} value={newFilter?.time?.time_start}>
                        {
                            timeStart.map(t=>
                                <option key ={"ts"+t} value={t}>
                                    {t+':00'}
                                </option>
                            )
                        }	
                        </select>           
                    </div>
                    <div>
                        <span>До</span> 
                        <select name="end" onChange={setTimeEnd} value={newFilter?.time?.time_end}>			
                        {
                            timeEnd.map(t=>
                                <option key ={"te"+t} value={t}>
                                    {t+':00'}
                                </option>
                            )
                        }	
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default FilterTimeChoice;