import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCortType, getCortCover } from '../Filters/InitFilter';
import {orderCalc} from '../../hooks/useOrderRecalc'


const OrderInfo = ({info, order, club, url}) => {
    const clubRule = info?.clubRule ? info?.clubRule : club?.clubRule
    order =  info ? info?.order : order;
    order =orderCalc(order)
    const textBack =() => info ? 'Інформація про бронювання' : 'Підтвердження бронювання'
    const navigate = useNavigate()
    return (
       <>
        <div className="header_tt frame frame_bt">
            <div className="back_to_club" onClick={()=>url?.back ? navigate(url.back) : null}>
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.991 511.991"><path d="M153.433 255.991L381.037 18.033c4.063-4.26 3.917-11.01-.333-15.083-4.229-4.073-10.979-3.896-15.083.333L130.954 248.616c-3.937 4.125-3.937 10.625 0 14.75L365.621 508.7a10.65 10.65 0 007.708 3.292c2.646 0 5.313-.979 7.375-2.958 4.25-4.073 4.396-10.823.333-15.083l-227.604-237.96z"></path></svg>
            </div>
            <div>{textBack()}</div>
            <div></div>
        </div>
        <section className="order">
            {(()=>info ? <OrderClubInfo info={info}/> : <OrderClubInfo info={order}/> )()}
            <div className="date_time frame frame_bt">
                <div className="date">
                    <div>Дата</div>
                    <div><b>{order?.date}</b></div>
                </div>
                <div className="time">
                    <div>час оренди</div>
                    <div><b>{order?.time?.time_start} - {order?.time?.time_end}</b></div>
                </div>
                <div className="duration">
                    <div>&nbsp;</div>
                    <div><b>{order?.duration} годин</b></div>
                </div>
            </div>
            <div className="price_section filter frame">
                <h3>Оренда корту</h3>
                <div className="date_time filter frame_bt">
                    <div>
                        <div className="name">Тип корта</div>
                        <div className="value">
                            <div> <b>{getCortType(order?.cort_type)}</b> </div>
                        </div>
                    </div>
                    <div>
                        <div className="name">Покриття</div>
                        <div className="value">
                            <div> <b>{getCortCover(order?.cort_cover)}</b> </div>
                        </div>
                        
                    </div>
                    <OrderTimeCost order={order}/>
                </div>
            </div>
            <div className="price_section filter frame">
                <h3>Додаткові послуги:</h3>
               <OrderAddService order={order}/>
            </div>
            <Total order={order}/>
            <Rules clubRule={clubRule}/>
        </section>
        </>
    );
};

const OrderTimeCost =({order})=>{
    const amountTime = order?.costTime ? order.costTime : order?.time?.price ? order.time.price : 0
    return(
        <div className="price">
            <i><s>{order.discount?.value >0 ? order?.time?.price : ""}</s></i> &nbsp;
            {order.costTime ? <TimeCostDiscount order={order}/> : ''}
            <div><b>&nbsp; ₴ {amountTime}</b></div>
        </div>
    )
}
const OrderAddService= ({order})=>{
    return(
        <div className="add_service frame_bt">
            {order?.add_service?.map(item=><AddService key={item.name} item={item}/>)}  
            {order.costService ? <AddServiceDiscount order={order}/>: ''}                         
        </div>
    )
}
const OrderClubInfo =({info})=>{
    return(
    <div className="orderInfo frame frame_bt">
        <div>
            <div>Клуб: </div>
            <h3>{info?.text}</h3>
        </div>
        {info?.id ?
            <div>
                <div>Бронь №: </div>
                <div>{info?.id}</div>
            </div>
            :''
        }
        <div>
            <div>Адреса: </div>
            <div>
                {info?.map 
                ? <a href={info.map}>{info?.address}</a>
                :<span>{info?.address}</span>
                }
            </div>
        </div>
        {info?.tel ?
            <div>
                <div>Телефон: </div>
                <div><a style={{textDecoration:'none'}} href={"tel:+"+info?.tel}>{'+'+info?.tel}</a></div>
            </div>
        : ''
        }
    </div>
    )
}

const AddService =({item})=>{
    return(
        <div>
            <div className="name">{item.text}</div>
            <div className="price">₴ {item?.sum}</div>
        </div>
    )
}
const AddServiceDiscount =({order})=>{
    const discountPer = order?.discount 
        ? order.discount?.add_service 
        ? -order.discount.add_service*100 
        : order.discount?.value 
        ? - order.discount.value*100
        : null : null
    const amount = order.costService;
    return (
        <div>
            <div><b>Разом</b></div>
            <div><i><s>{order?.priceService ? order.priceService : ""}</s></i>&nbsp;
                {
                    discountPer?
                    <div className='timeCostDiscount'>
                        <div>{discountPer + '%'}</div>
                    </div>
                    :''
                }
                &nbsp;<div><b>{amount}</b></div>
            </div>
        </div>
    )
}
const TimeCostDiscount =({order})=>{
    const discountPer = order?.discount?.value ?  -order.discount.value*100 : null;
    return (
        discountPer ?
        <div className='timeCostDiscount'>
            <div>{discountPer + '%'}</div>
        </div>
        :""
    )
}


const Total =({order})=>{
    const discount =()=> order?.discount?.value ? order.discountCalc ? order.discountCalc
                        : order.discount.value*100 : 0
             
    return(
        <div className="price_section filter frame frame_bt">
            <div className="total price">
                <div><b>Разом</b></div>
                <div>
                    <div className="priceWithoutDiscount"><b>{order?.price} ₴</b></div>
                </div>
            </div>
            {discount()?
            <div className="total discount" style={{display:'flex'}}>
                <div>Знижка</div>
                <div>
                    <div><span>{ Math.round(discount()) }%</span></div>
                    <div>{order?.price && order?.amount ? Math.round(100*(order?.price - order?.amount))/100 : ""} ₴</div>
                </div>
            </div>
            :''}
            {order?.amount?
            <div className="total amount" style={{display:'flex'}}>
                <div>
                    <h3><b>До сплати</b></h3>
                </div>
                <div>
                    <div><b style={{fontSize:'20px'}}>{order?.amount} ₴</b></div>
                </div>
            </div>
            :''}
        </div>
    )
}
const Rules =({clubRule})=>{
    return(
        <div>
             <div className="payment frame">
                <div>Розрахунок</div>
                <div><span>онлайн платіж</span></div>
            </div>
            <div className="rules frame frame_bt">
                <div>Правила скасування бронювання:</div>
                <div><a target="new" href="https://docs.google.com/document/d/1-UTGpz4d6hvoOlVqOsbYI2TUWVtzBu4-biCMgoyL6yM/edit?usp=sharing">Помірні</a></div>
            </div>
            <div className="rules frame frame_bt">
                <div>Політика повернення коштів:</div>
                <div><a target="new" href="https://docs.google.com/document/d/19mmTIkuC0QXxWtIkFMDBh22OCzj3lPH3D6MzL_rbfBk/edit?usp=sharing">Ознайомитися</a></div>
            </div>
            <div className="rules frame frame_bt">
                <div>Правила клубу:</div>
                {clubRule ? <div><a target="new" href={clubRule}>Ознайомитися</a></div>: ''}
            </div>
            <p style={{paddingBottom:'70px'}} className="frame">Натискаючи кнопку «Далі» я погоджуюся з правилами скасування бронювання та правилами клубу</p>
        </div>
    )
}

export default OrderInfo;