import React, {useState} from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import {Link, useNavigate} from "react-router-dom";
import {confirmSms} from '../../firebase/firebaseAuthPhone'

import { composeAsync } from '../../js/function';
import { setToken } from '../../authMethods';
import {motion} from 'framer-motion'
import FormSubmitBottom from '../../Components/UI/FormSubmitBottom'

const SmsVerification =()=>{
    const navigate = useNavigate();
    const confirmationResult =()=> window.confirmationResult
    const [value, setValue] = useState("");
    const [errSms, setErrSms] = useState(false)
    const [load, setLoad] = useState(false)

    const onSubmit =async (e)=>{
       e.preventDefault();
       setLoad(true)
       const sms = value
       const  userData = await getUser(sms)
       setTokenLocal(userData?.token)
       userData ? navigate('/info') : errCode()
       setLoad(false)
    }
    const errCode =()=>setErrSms(true)
    const setTokenLocal =(token)=> token ? setToken(token) : null
    
    const getToken =(sms)=> composeAsync(checkSms, getUid)(sms)
    const getUser =(sms)=> composeAsync(getToken, getObject)(sms)
    const checkSms =async (sms)=> sms.length>0 ? confirmationResult() ?
                  await confirmSms(confirmationResult(),sms): null : null
    const getUid =(userData) => userData ? userData.uid ? userData.uid : null : null
    const getObject = (uid)=> uid ? {isAuth: true, token:uid} : null
       
    
    return(
        <motion.section
             className="sms_confirm" id="sms_confirm"
             initial ={{scale:0.6}}
             animate={{scale:1}}
             exit ={{opacity:0}}
        >
            <Link to ="/">
            <div className="back" id="sms_back">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823"><path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"></path></svg>
            </div>
            </Link>
            <div className="frame smsEnter">
                <form className={errSms ?'sms err_phone_login':'sms'} id="sms_form" onSubmit={onSubmit}>
                    <h1>Введіть код</h1>
                    <p>який ми вислали вам в SMS</p>
                    <div className='smsBlocksFrame'>
                        <ReactInputVerificationCode
                            length = {6}
                            autoFocus
                            placeholder=""
                            onChange={setValue}
                            value={value}
                        />
                    </div>
                    <p className="err_msg"><span>Перевірте правильність заповнення коду смс повідомлення</span></p>
                    <FormSubmitBottom load={load}/>
                </form>
            </div>
        </motion.section>
    )
}

export default SmsVerification

