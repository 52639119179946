import React from "react";
import  {ReactComponent as WaitingSvg} from '../svg/waitingSvg.svg'

const Waiting=()=>{
    return(
        <section className="background">
            <div className="animatedLogo">
                <WaitingSvg/>
            </div>
        </section>
    )
}

export default Waiting