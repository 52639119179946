import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import Parser from 'html-react-parser';
import {func_round} from '../../js/function';
import Discount from '../UI/Discount';
import CortFilterList from '../UI/CortFilterList';
import MyClub from '../UI/MyClub';
import {motion} from 'framer-motion'

const ClubItem = (props) => {
    const club = props.club
    const clubId = club.url ? club.url : 'club_'+club.id 
    const navigate = useNavigate()
    const [myClubId, setMyClubId] = useState(club.my_club)
    const checkChangeMyClubs =()=> props.checkChangeMyClubs ?  props.checkChangeMyClubs() : null
    const setMyClub =(clubId)=>{
        setMyClubId(clubId)
        checkChangeMyClubs()
    }
    const gotoClub =()=>{
        const clubUrl =(club)=>'club_'+club.id
        navigate(clubUrl(club))
    }
  
    return (
    <motion.div className='clubItem'
        initial ={{scale:0.8}}
        animate={{scale:1}}
        exit ={{opacity:0}}>
        <div className="club frame frame_bt" onClick={gotoClub}>
            <div className="img_container">
                <div className="img">
                    <img src={club.imageUrl} alt={club.title}/>
                    <div className="name_and_price">
                        <div className="clubInfo">
                            <h2 className="name">{club.title}</h2>
                            <div className="price"><b>~</b>{func_round(club.priceAverage)} грн</div>
                        </div>
                        <Discount club={club}/>
                    </div>
                </div>
            </div>
            <div>
                <div className="adress_a_marks frame_bt_10">
                    <a href={club?.map} target="_blank" rel="noreferrer" className="adress">
                        <div className="icon"> <svg height="682pt" viewBox="-119 -21 682 682.667" width="682pt" xmlns="http://www.w3.org/2000/svg"><path d="M216.21 0C93.548 0-6.25 99.797-6.25 222.46-6.25 376.638 216.43 640 216.43 640s222.242-270.945 222.242-417.54C438.672 99.798 338.879 0 216.21 0zm67.122 287.598c-18.508 18.504-42.812 27.757-67.121 27.757-24.305 0-48.617-9.253-67.117-27.757-37.012-37.008-37.012-97.227 0-134.239 17.922-17.93 41.761-27.804 67.117-27.804 25.355 0 49.191 9.879 67.121 27.804 37.012 37.012 37.012 97.23 0 134.239zm0 0"></path></svg>                        </div>
                        {club.distance ? <div> <b> {club.distance + ' км'} </b></div>: '' }
                        <div> <span><b>{club.city_text}</b>, {club.address}</span> </div>
                    </a>
                </div>
                <CortFilterList list={club.courtCoverTypes} className={'filter_list main'}/>
                <CortFilterList list={club.courtTypes} className={'filter_list frame_bt_10'}/>
                <div className="timetables">
                    <div className="svg">{Parser(club.availableUrlLight)}</div>
                </div>
                <div className="btn_w_g">
                    <span>
                        <Link to = {clubId}>
                                Перейти / Забронювати 
                        </Link>
                    </span>
                </div>
            </div>
        </div>
        <div className="like_my_club">
         <MyClub myClubId = {myClubId} clubId ={club.id} setMyClub={setMyClub}/>
        </div>
    </motion.div>
    );

};

export default ClubItem;