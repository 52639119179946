import React from 'react';

const FormSubmitBottom =({load, text})=>{
    return(
        <div className={load ? 'submit-load' : 'submit'} id="submit_sms_container">
            <input id="submit_sms" type="submit" value= {text ? text : "Надіслати" }/>
            <span>З'єднення</span>
        </div>
    )
}

export default FormSubmitBottom