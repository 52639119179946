import React, {useState} from 'react';
import Parser from 'html-react-parser';
import {useLocation, useNavigate } from 'react-router-dom';
import Discount from '../UI/Discount';
import CortFilterList from '../UI/CortFilterList';
import { func_round } from '../../js/function';
import {func_dateToString, func_getTimeString} from '../../js/function'
import MyClub from '../UI/MyClub';
import {getUpperLocation} from '../../js/function'
import {motion} from 'framer-motion'

const ClubInfo = ({club, filter, openFilter}) => {
    const {d_m_y} = func_dateToString()
    const ts = func_getTimeString(filter.time.time_start)
    const te = func_getTimeString(filter.time.time_end)
    const navigate = useNavigate();
    const location = useLocation();
    const styleAvailable =() => club.main_service ? {display:'none'} : {display:'block'}
    const goBack =()=> {
        const upperLink = getUpperLocation(location.pathname)
        navigate(upperLink)
    }

    const [myClubId, setMyClub] = useState(club.my_club)
    return(
        <motion.section 
            className="info"
            initial ={{scale:0.6}}
            animate={{scale:1}}
            exit ={{opacity:0}}
        >
            <div className="img"> <img src={club.imageUrl} alt={club.title}/>
                <div className="close" onClick={()=>goBack()}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001"><path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path></svg>                </div>
                <MyClub myClubId = {myClubId} clubId ={club.id} setMyClub={setMyClub}/>
            </div>
            <div className="name_and_price frame">
                <div>
                    <h1 className="name">{club.title}</h1>
                    <Discount club={club}/>
                </div>
                <div className="price">
                    <div> <b>~{func_round(club.priceAverage)}</b></div>
                    <div>грн</div>
                </div>
            </div>
            <div className="adress_a_marks frame frame_bt border_bottom">
                <a href={club?.map ? club.map : ''} rel="noreferrer" target="_blank" className="adress">
                    <div className="icon"> <svg height="682pt" viewBox="-119 -21 682 682.667" width="682pt" xmlns="http://www.w3.org/2000/svg"><path d="M216.21 0C93.548 0-6.25 99.797-6.25 222.46-6.25 376.638 216.43 640 216.43 640s222.242-270.945 222.242-417.54C438.672 99.798 338.879 0 216.21 0zm67.122 287.598c-18.508 18.504-42.812 27.757-67.121 27.757-24.305 0-48.617-9.253-67.117-27.757-37.012-37.008-37.012-97.227 0-134.239 17.922-17.93 41.761-27.804 67.117-27.804 25.355 0 49.191 9.879 67.121 27.804 37.012 37.012 37.012 97.23 0 134.239zm0 0"></path></svg>                    </div>
                    {club.distance ? <div> <span>{club.distance + ' км'}</span> </div>: '' }
                    <div> <span><b>{club.city_text}</b>, {club.address}</span> </div>
                </a>
            </div>
            <div className="club_item time frame frame_bt">
                <div data-name="date" className="btn_w activeBtn" onClick={()=>openFilter('date')}>
                    <span>{d_m_y(filter.date)}</span>
                </div>
                <div data-name="time" className="btn_w activeBtn" onClick={()=>openFilter('time')}>
                    <span>{ts}</span>
                    <span>&nbsp;&nbsp; - &nbsp;&nbsp;</span>
                    <span>{te}</span>
                </div>
            </div>
            <div className="freeTimeMsg frame" style={styleAvailable()}>
                 <span className="frame frame_bt">На жаль, для обраного часу і дати у клубі немає вільних кортів</span>
            </div>
            <div className="frame frame_b border_bottom">
                <div className="timetables">
                    <div className="svg">{Parser(club.availableUrlLight)}</div>
                </div>
            </div>
            <div className="filter_value frame frame_bt" data-name="courtTypes">
                <div>
                    <div className="title">Тип корту:</div>
                    <CortFilterList list={club.courtTypes} className={'value'}/>
                </div>
                <div>
                    <div className="title">Покриття:</div>
                    <CortFilterList list={club.courtCoverTypes} className={'value'}/>
                </div>
            </div>
            <div className="rules frame frame_bt">
                <div>Правила бронювання:</div>
                <div><a target="new" href="https://docs.google.com/document/d/1-UTGpz4d6hvoOlVqOsbYI2TUWVtzBu4-biCMgoyL6yM/edit?usp=sharing">Помірні</a></div>
            </div>
        </motion.section>
    )
};


export default ClubInfo;