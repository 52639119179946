import React from 'react';
import { useState, useEffect, useContext } from 'react';
import {AuthContext } from '../../context'
import GetService from '../../API/getService';
import ClubItem from '../../Components/ClubList/ClubItem';
import Waiting from '../../Components/UI/Waiting';
import {useSortByGeo} from '../../hooks/useFilter'

const Myclub = ({filter}) => {
    const {geo} = useContext(AuthContext) 
    const sortByGeo = useSortByGeo(geo) 
    const [clubs, setClubs] = useState([])
    const [load, setLoad] = useState(false)
    const [changeClubs, setChangeClubs] = useState(0)
    const checkChangeMyClubs = ()=> setChangeClubs(changeClubs+1)
    const fetchClubs = async() => {
        setLoad(false)
        const clubArray = await GetService.getMyClubList(filter)
        
        clubArray ? setClubs(sortByGeo(clubArray)) : setClubs([])
        setLoad(true)
    }
    useEffect(()=>{
        fetchClubs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeClubs])
    return load ? clubs?.length > 0 ? myClubList(clubs, checkChangeMyClubs) : noMyClub() : <Waiting/>
};

const myClubList =(clubs, checkChangeMyClubs)=>{
    return(
        <section className="contentWrapper">
        <main className="clubs"
            initial ={{left:"100%"}}
            animate={{left:0}}
            exit ={{opacity:0}}>
            <h1 className='frame'>Мої клуби</h1>
            <div className='clubList'>
             {clubs.map(club=> <ClubItem key = {club.id} club={club} checkChangeMyClubs={checkChangeMyClubs}/>)}
             </div>
        </main>
        </section>
    )
}

const noMyClub =()=>{
    return(
        <main>
            <div className='contentWrapper'>
            <div className="no_search_results">
                <div className="frame frame_bt">
                    <h3>Тут будуть відображатися ваші обрані клуби,</h3><br/>
                     <p>Для того, щоб додати клуб натисніть 🤍. </p><br/>
                     <p>Для того, щоб налаштувати повідомлення від клубів, перейдіть в розділ «Повідомлення» у вашому профілі.</p>
                </div>
            </div>
            </div>
        </main>
    )
}

export default Myclub;