

export const useFilterByCity = (filter) =>(clubArray)=>{
    const checkCity = (club)=>{
        return club.city === filter.city.nameUkr ? true : false
    }
    return filter.city ? filter.city.id > 0 
            ? [...clubArray].filter(checkCity)
            : clubArray : clubArray
}

export const useSortByGeo =(geo)=>(clubArray)=>{
	const geoSort = clubArray.map(club => setDistance(geo, club))
              .sort((a, b) =>a.distance - b.distance)
	const clubsWithTime = ()=>geoSort.filter(el=>el.ms_id != null || undefined)
	const clubsWithoutTime =()=> geoSort.filter(el => el.ms_id ===null )
	const clubsWithoutMs =()=> geoSort.filter(el => el.ms_id ===undefined )
	return [...clubsWithTime(), ...clubsWithoutTime(), ...clubsWithoutMs()]
}
   
export const useSetDistance =(geo)=>(club)=>setDistance(geo, club)
	
const setDistance =(geo,club)=>{
    const userLoc = geo ? geo?.coords ? geo.coords : null : null
    return userLoc ? club?.lat && club.lng
         ?{...club, distance:calcDistance(userLoc.latitude, userLoc.longitude, club.lat, club.lng)} 
         :{...club, distance: null}
		 :{...club, distance: null}
}
const calcDistance =(lat_1, lng_1, lat_2, lng_2)=>{
	try{
		const EARTH_RADIUS = 6372795;
		// перевести координаты в радианы
		let lat1 = lat_1 * Math.PI / 180;
		let lat2 = lat_2 * Math.PI / 180;
		let long1 = lng_1 * Math.PI / 180;
		let long2 = lng_2 * Math.PI / 180;
		 
		// косинусы и синусы широт и разницы долгот
		let cl1 = Math.cos(lat1);
		let cl2 = Math.cos(lat2);
		let sl1 = Math.sin(lat1);
		let sl2 = Math.sin(lat2);
		let delta = long2 - long1;
		let cdelta = Math.cos(delta);
		let sdelta = Math.sin(delta);
		 
		// вычисления длины большого круга
		let y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
		let x = sl1 * sl2 + cl1 * cl2 * cdelta;
		// //console.log(y,x)
		//
		let ad = Math.atan(y,x);
		let dist = Math.round(Math.abs((ad * EARTH_RADIUS))/1000);
		
		return dist;
	}catch{
		return null;
	}	
}
