import React from 'react';
import { useGetOrder } from '../../../hooks/useOrderObject';

const AddServiceBlock = ({service, discount, pushAddService}) => {
    const add_service = [...service.add_service]
    return (
        <div className={"additional_service frame frame_bt "+service.name}>
            <div className="title">
                <h3>
                    {service.original_text}
                    {discount > 0 ? <DiscountAddServiceValue discount={discount}/> : ''}
                </h3>
                <p>{service.description}</p>
            </div>
            <div className="option">
                {add_service.map(item=><AddServiceItem key={'id_'+item.id} item={item} discount={discount} pushAddService={pushAddService}/>)}
                <p>Після бронювання тестової послуги наш оператор зв`яжеться з Вами і в разі якщо тестова послуга не буде надана, вона не буде враховуватися в поточній бронювання оренди корту</p>
            </div>
        </div>
    );
};
const AddServiceItem =({item, discount, pushAddService})=>{
    const order = useGetOrder()
    const duration =()=> order?.time?.timeEnd - order?.time?.timeStart >= 1 ?  order?.time?.timeEnd - order?.time?.timeStart : 0
    const active =()=> order?.add_service ? order.add_service.filter(s=> s.id === item.id).length>0 
                    ? 'active' : '' : ''
    
    const cost =(item, dur)=> {
        switch (item.price_unit){
            case 'per_hour': return item.price*dur 
            case 'one_time': return item.price
            default : return item.price
        }
    }
    const setAddServiceCost =(item, dur)=>  pushAddService({...item, cost: cost(item, dur)})
    const calc =(item, dur)=> {
        switch (item.price_unit) {
            case 'per_hour': return {sum:Math.round(cost(item, dur) * (1-discount)*100)/100, expression: '('+dur +'ч x '+item.price+')'}
            case 'one_time': return {sum:Math.round(cost(item, dur) * (1-discount)*100)/100, expression:''}
            default : return {sum:Math.round(cost(item, dur)* (1-discount)*100)/100, expression:''}
        }
    }
    return(
        <div className={"service_option "+active()} data-id={item.id} onClick={()=>setAddServiceCost(item, duration())}>
            <div>
                <div className="name">{item.text}</div>
                <div className="price"><b>{item.price} грн</b> {item.price_unit_txt}</div>
            </div>
            <div className="calc per_hour">
                <div>₴ {calc(item,duration())?.sum}</div>
                <div><span>{calc(item,duration())?.expression}</span></div>
            </div>
            <div>
                <div className="checkbox">
                    <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z"></path></svg></div>
                </div>
            </div>
        </div>
    )
}
const DiscountAddServiceValue =({discount})=>{
  return(
         <div className="discount"><div><span> - {discount*100} %</span></div></div> 
    )
}
export default AddServiceBlock;