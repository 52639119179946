import React from 'react';

const OrderCancel = () => {
    return (
        <section className="cancelOrder" style={{paddingTop:'10px'}}>
            <div className='cancel btn_red' style={{textAlign:'center'}}>
                Відмінити
            </div>
        </section>
    );
};

export default OrderCancel;