import '../../css/profileMsg.css';
import React, {useState} from 'react';
import BackToProfile from "./BackToProfile";
import {postUserInfoAuth} from '../../hooks/GetUserInfo'
import {motion} from 'framer-motion'

function MsgInfo({userInfo, changeInfo}) {
    const pageName ='Повідомлення'
    return (
        <motion.div className="info personInfo"
        initial ={{scale:0.6}}
        animate={{scale:1}}
        exit ={{opacity:0}}>
            <BackToProfile pageName={pageName} />
            <PrintChecks userInfo={userInfo} changeInfo={changeInfo}/>
        </motion.div>
    );
}

const PrintChecks =({userInfo, changeInfo})=>{
    const [userData, setUserData] = useState(userInfo.info)
    const changeUserData = (field)=> {
        const newInfo = {...userData, ...field}
        setUserData(newInfo)
        changeInfo({...userInfo, info:newInfo})
        postUserInfoAuth(newInfo)
    }
    console.log('changeUserData', userData)
    const changeAllEmail =async (status)=>{
        const newInfo = {...userData,
            ...{
                notification_email : status,
                notification_email_advertising :status,
                notification_email_news : status,
                notification_email_partnerNews : status
            }
        }
        setUserData(newInfo)
        changeInfo({...userInfo, info:newInfo})
        postUserInfoAuth(newInfo)
    }
    
    return(
    <div className="activationMsg">
        <ul className="frame frame_bt">
            <li>
                <div>
                    <div>
                        <div>SMS повідомлення</div>
                    </div>
                    <div>
                        <CheckBox 
                            name ='notification_sms'
                            userData = {userData}
                            changeUserData ={changeUserData}
                        />
                    </div>
                </div>
                <p>
                    Отримувати оперативні повідомлення протягом дня про вільний час у клубах вашого міста у прайм тайм, або про цікаві зниження вартості оренди корту. У разі зміни цього параметру, зміни вступлять в силу протягом 5 робочих
                    днів.
                </p>
            </li>
            <li>
                <div>
                    <div>
                        <div>Push - повідомлення</div>
                    </div>
                    <div>
                         <CheckBox 
                            name ='notification_push'
                            userData = {userData}
                            changeUserData ={changeUserData}
                        />
                    </div>
                </div>
                <p>Тільки важливі повідомлення та повідомлення щодо підтвердження або скасування бронювання</p>
            </li>
        </ul>
        <ul className="frame frame_bt">
            <h3>Email повідомлення:</h3>
            <li>
                <div>
                    <div>
                        <div>вкл / викл усі email повідомлення</div>
                    </div>
                    <div>
                        <CheckBoxAllEmail 
                            userData = {userData}
                            changeAllEmail ={changeAllEmail}
                        />
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div>
                        <p>Отримувати інформацію про актуальні новини Book4sport</p>
                    </div>
                    <div>
                        <CheckBox 
                            name ='notification_email_news'
                            userData = {userData}
                            changeUserData ={changeUserData}
                        />
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div>
                        <p>Отримувати інформацію щодо партнерів Book4sport</p>
                    </div>
                    <div>
                        <CheckBox 
                            name ='notification_email_partnerNews'
                            userData = {userData}
                            changeUserData ={changeUserData}
                        />
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div>
                        <p>Отримувати рекламну інформацію</p>
                    </div>
                    <div>
                        <CheckBox 
                            name ='notification_email_advertising'
                            userData = {userData}
                            changeUserData ={changeUserData}
                        />
                    </div>
                </div>
            </li>
        </ul>
    </div>
    )
}

const CheckBox =({name, userData, changeUserData})=>{
    const onClick =()=>userData[name] 
        ? changeUserData({[name]:false})
        : changeUserData({[name]:true})
    return(
        <div className="btnIOS" data-name={name} onClick ={onClick}>
            <div className={ userData[name] ? 'checked' : ''}><span></span></div>
        </div>
    )
}
const CheckBoxAllEmail =({userData, changeAllEmail})=>{
    const onClick =()=>userData['notification_email'] 
        ? changeAllEmail(false)
        : changeAllEmail(true)
    return(
        <div className="btnIOS" data-name='notification_email' onClick ={()=>onClick()}>
            <div className={ userData['notification_email'] ? 'checked' : ''}><span></span></div>
        </div>
    )
}

export default MsgInfo;