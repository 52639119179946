import '../../css/time.css';

import React, {useState} from 'react';
import FilterBigEl from '../UI/FilterBigEl';
import {useGetTimeTable} from '../../hooks/useClubTime'
import TimeChose from './Time/TimeChose';
import {useDispatch } from 'react-redux'
import {setOrderData} from '../../store/orderSlice'
import {useGetOrder, useOrderObject } from '../../hooks/useOrderObject';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion'
import {func_dateToString} from '../../js/function'


const ClubInfoTime = ({club, filter, url}) => {
    const {d_m_y} = func_dateToString() 
    const getOrder = useGetOrder
    const getOrderObject = useOrderObject
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const initTypeValue = club.main_service ? club.main_service[0].cort_type_name : null
    const [typeValue, setTypeValue] = useState(initTypeValue)
    const [order, setNewOrder] = useState(getOrder())
    const cortType = filter.cortTypeList
        .filter(type => club.main_service.some( ms=> ms.cort_type_name === type.name))
        .map((c, i)=> c.name === typeValue ? {...c, active:true} : {...c, active:false})

    const clickCortType =(el)=> setTypeValue(el.name)

    const timeTable = useGetTimeTable(club, typeValue)
    const getOrderTime = (interval) => {
        const obj = getOrderObject(interval, club)
        const execute =()=> obj ? dispatch(setOrderData(obj)) 
                ? (()=>{ 
                    setNewOrder(getOrder())
                    return true
                  })()
                : null : null
        
        return execute()
    }
    
    return (
 
        <motion.section
             className="time_selection info"
             initial ={{scale:0.6}}
             animate={{scale:1}}
             exit ={{opacity:0}}
        >
            <div className="header_tt frame frame_bt">
                <div className="back_to_club" onClick={()=>url?.back ? navigate(url.back) : null} > 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.991 511.991"><path d="M153.433 255.991L381.037 18.033c4.063-4.26 3.917-11.01-.333-15.083-4.229-4.073-10.979-3.896-15.083.333L130.954 248.616c-3.937 4.125-3.937 10.625 0 14.75L365.621 508.7a10.65 10.65 0 007.708 3.292c2.646 0 5.313-.979 7.375-2.958 4.25-4.073 4.396-10.823.333-15.083l-227.604-237.96z"></path></svg>                
                </div>
                <div>{d_m_y(filter.date)}</div>
                <div></div>
            </div>
            <div className="filter frame frame_bt">
                <div className="title">Тип корту</div>
                <div className="filter_type1">
                     {cortType.map(el=><FilterBigEl  click={clickCortType} key={el.name} el={el}/>)}  
                </div>
            </div>
            <div className="time_container frame frame_bt">
                <div className="time_table">
                    <div className="scale"></div>
                    {timeTable? timeTable.map(time=><TimeChose
                        key={'id'+time.club_main_service_id}
                        time={time} 
                        getOrderTime={getOrderTime}/>):""}
                </div>
            </div>
        </motion.section>
    );
};

export default ClubInfoTime;