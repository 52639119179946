import React from 'react';
import Parser from 'html-react-parser';

const FilterBigEl = ({el, click}) => {
   
    return (
        <div onClick={()=>click(el)} className= {el.active ? 'filter_icon checked' : 'filter_icon'}>
            <div className="icon">
                {Parser(el.icon)}
            </div>
            <div className="text">{el.original_text}</div>
        </div> 
    );
};

export default FilterBigEl;