import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {authPhoneNumber, setRecaptcha, getRecaptcha} from '../../firebase/firebaseAuthPhone'
import {motion} from 'framer-motion'
import FormSubmitBottom from '../../Components/UI/FormSubmitBottom'


function PhoneEnter(){
    
    const navigate = useNavigate()
    const submit = 'submit_phone'
    const [load, setLoad] = useState(false)
    
    const [errPhone, setErrPhone] = useState(false)
   
    const classPhone = () => errPhone ? 'number errPhone' : 'number'
    const proceed =(confirmationResult)=> confirmationResult ? navigate('/sms') : setErrPhone(true)
    const onSubmit = async (e)=> {
        setLoad(true)
        console.log('onSubmit',e)
        e.preventDefault();
        const phone ='+' + value
        const appVerifier = errPhone ? getRecaptcha() : setRecaptcha(submit)
        const confirmationResult = value ? await authPhoneNumber(appVerifier, phone) : null
        console.log('confirmationResult', confirmationResult)
        proceed(confirmationResult)
        setLoad(false)
    }
    const [value, setValue] = useState()
    
    return (
    <motion.div 
        initial ={{scale:0.6}}
        animate={{scale:1}}
        exit ={{opacity:0}}
    >
        <div className='frame phone_enter'>
        <section className='regInfo'>
        <h1>Ваш номер телефону</h1>
        <form className={errPhone ?'phone_login err_phone_login' : 'phone_login'} onSubmit={onSubmit}>
            <div className={classPhone()}>   
            <PhoneInput
                country={'ua'}
                value={value}
                onChange={setValue}
                inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true
                }}
            />
            </div>
            <p className="err_msg"><span>Перевірте правильність заповнення телефонного номера</span></p>
            <p>Вам буде висланий 6-значний код через SMS для підтвердження вашого номера телефону</p>
            <p className="user_condition">
                <input type="checkbox" required style={{marginRight:'20px'}}/>
                <a href="https://docs.google.com/document/d/12QLMexu-z2THM1293ARbPAJrNiZ8XHC3c76bFqOlIAM/edit?usp=sharing">З угодою користувача ознайомлений</a>
            </p>
            <p className="user_condition">
                <input type="checkbox" required style={{marginRight:'20px'}} />
                <a href="https://docs.google.com/document/d/1yMDQ9Sbjhi2CqxBIIeGbEJCd7U8h0mPUoUIsy-ziKy8/edit?usp=sharing">З політикою використання файлів cookie ознайомлений і згоден на їх використання</a>
            </p>
            <p className="user_condition">
                <input type="checkbox" required style={{marginRight:'20px'}} />
                <span>Я підтверджую, що мені виповнилося 16 років</span>
            </p>
            <FormSubmitBottom load={load}/>
        </form>
        <div className ="reCaptcha" id={submit}></div>
        </section>
        </div>
    </motion.div>

)

}

export default PhoneEnter