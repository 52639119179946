import {initializeApp} from 'firebase/app';
//import {getFirestore} from 'firebase/firestore/lite';
//import {collection, getDocs} from 'firebase/firestore/lite';
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {firebaseConfig} from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
//const db = getFirestore(app);
const auth = getAuth(app);
auth.languageCode = 'uk';

export const setRecaptcha =(submit)=>{
  window.recaptchaVerifier = new RecaptchaVerifier(submit, {
    'size': 'invisible',
    'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
    }
    }, auth)
  //const phoneNumber = getPhoneNumberFromUserInput();
  return  window.recaptchaVerifier;
}
export const getRecaptcha =()=> window.recaptchaVerifier;
export const authPhoneNumber = async(appVerifier, phoneNumber)=>{
    // To apply the default browser preference instead of explicitly setting it.
    // firebase.auth().useDeviceLanguage();
 
    return await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      return true
      // ...
    }).catch((error) => {
        console.log('signInWithPhoneNumber', error)
        return null
      // Error; SMS not sent
      // ...
    })
    
}
export const confirmSms =async (confirmationResult, sms) =>confirmationResult.confirm(sms).then((result) => {
    // User signed in successfully.
    const user = result.user;
    return user
    // ...
    }).catch((error) => {
        console.log('confirmSms',error)
        return(false)
    // User couldn't sign in (bad verification code?)
    // ...
});

