import '../../css/orders.css';
import React from 'react';
import { useState, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import GetService from '../../API/getService';
import {func_dateToString, func_getDateNumber} from '../../js/function'
import {motion} from 'framer-motion'
import Waiting from '../../Components/UI/Waiting';

const Orders = () => {
    const dateNumber =(date) => {
        const {y_m_d} = func_dateToString()
        return func_getDateNumber(y_m_d(date))
    }
    const [load, setLoad] = useState(true)
    const [orderCurrent, setOrderCurrent ] = useState([])
    const [orderHistory, setOrderHistory ] = useState([])
    const getOrderCurrent =(orders)=>{
        return orders ? orders.filter(order => dateNumber(new Date()) <= func_getDateNumber(order.date)) : []
    }
    const getOrderHistory =(orders)=>{
        return orders ? orders.filter(order => dateNumber(new Date()) > func_getDateNumber(order.date)) : []
    }
    const fetchOrders = async() => {
        setLoad(true)
        const orderList = await GetService.getOrders()
        setOrderCurrent(getOrderCurrent(orderList))
        setOrderHistory(getOrderHistory(orderList))
        setLoad(false)
    }
    useEffect(()=>{
        fetchOrders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        !load ?
        <section className= 'contentWrapper'>
        <motion.main
            initial ={{scale:0.6}}
            animate={{scale:1}}
            exit ={{opacity:0}}>
            <h1 className="frame">Бронювання</h1>
           {orderCurrent.length > 0 ? <OrderCurrent orders={orderCurrent}/> : ''}
           {orderHistory.length > 0 ? <OrderHistory orders={orderHistory}/> : ''}
        </motion.main>
        </section>
        : <Waiting/>
    );
};
const OrderCurrent =({orders})=>{

    return(
        <section className="last_order frame frame_bt order_html" data-id="2590">
            <h2>Поточні</h2>
            {orders.map(order=> <OrderCurrentItem key={'id_'+order.id} order={order}/>)}
        </section>
    )
}
const OrderCurrentItem =({order})=>{
    //console.log('OrderCurrentItem', order)
    const navigate = useNavigate();
    const orderUrl =()=> './order_'+ order.id
    const canceled =()=> order?.canceled ? true : false
    const imgUrl =()=> order?.img.length>0 ? order.img[0].imgUrl : ''
    const imgAlt =()=> order?.img.length>0 ? order.img[0].alt : ''
    return(
        <div onClick ={()=>navigate(orderUrl())}>
            <div className="img_container ">
                <div className="img"> 
                    <img src={imgUrl()} alt={imgAlt()}/>
                    <div className="name_and_price">
                        <h1>
                            <div className="name">{order.text}; №{order.id}</div>
                        </h1>
                    </div>
                </div>
            </div>
            <div className="order_info">
                <div>
                    <div>
                        <div>
                            <div> <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M448 64h-21.332V21.332C426.668 9.559 417.109 0 405.332 0H384c-11.777 0-21.332 9.559-21.332 21.332V64H149.332V21.332C149.332 9.559 139.777 0 128 0h-21.332C94.891 0 85.332 9.559 85.332 21.332V64H64C28.715 64 0 92.715 0 128v320c0 35.285 28.715 64 64 64h384c35.285 0 64-28.715 64-64V128c0-35.285-28.715-64-64-64zm21.332 384c0 11.754-9.578 21.332-21.332 21.332H64c-11.754 0-21.332-9.578-21.332-21.332V214.187h426.664zm0 0"></path></svg>                            </div>
                            <div>{order.date}</div>
                        </div>
                        <div>&nbsp;&nbsp;<span>•</span> {order?.order?.time?.time_start} -{order?.order?.time?.time_end}</div>
                    </div>
                    <div>
                        <div>
                            <div> <svg height="682pt" viewBox="-119 -21 682 682.667" width="682pt" xmlns="http://www.w3.org/2000/svg"><path d="M216.21 0C93.548 0-6.25 99.797-6.25 222.46-6.25 376.638 216.43 640 216.43 640s222.242-270.945 222.242-417.54C438.672 99.798 338.879 0 216.21 0zm67.122 287.598c-18.508 18.504-42.812 27.757-67.121 27.757-24.305 0-48.617-9.253-67.117-27.757-37.012-37.008-37.012-97.227 0-134.239 17.922-17.93 41.761-27.804 67.117-27.804 25.355 0 49.191 9.879 67.121 27.804 37.012 37.012 37.012 97.23 0 134.239zm0 0"></path></svg>                            </div>
                            <div></div>
                        </div>
                        <div>
                            <div> <span>•</span> <i>{order.address}</i></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div><b>₴ {order.sum}</b></div>
                    <div>{canceled() ? <i style={{color:'red'}}>Скасовано</i> : <i>Безготівкова</i>}</div>
                </div>
            </div>
        </div>
    )
}
const OrderHistory =({orders})=>{
    return(
        <div className="history frame frame_bt">	
        	<h2>Історія</h2>
            {orders.map(order=> <OrderHistoryItem key={'id_'+order.id} order={order}/>)}	
        </div>

    )
}
const OrderHistoryItem =({order}) =>{
    const navigate = useNavigate();
    const orderUrl =()=> './order_'+ order.id
    const canceled =()=> order?.canceled ? true : false
    const info = order.order
    return(
        <section onClick ={()=>navigate(orderUrl())} className="order_item frame_bt order_html" data-id="2589">
            <div className="name">
                <div><h3>{order.text}</h3></div>
                <div><b>₴ {order.sum}</b></div>
            </div>
            <div className="info">
                <div>
                    <div>
                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M448 64h-21.332V21.332C426.668 9.559 417.109 0 405.332 0H384c-11.777 0-21.332 9.559-21.332 21.332V64H149.332V21.332C149.332 9.559 139.777 0 128 0h-21.332C94.891 0 85.332 9.559 85.332 21.332V64H64C28.715 64 0 92.715 0 128v320c0 35.285 28.715 64 64 64h384c35.285 0 64-28.715 64-64V128c0-35.285-28.715-64-64-64zm21.332 384c0 11.754-9.578 21.332-21.332 21.332H64c-11.754 0-21.332-9.578-21.332-21.332V214.187h426.664zm0 0"
                            ></path>
                        </svg>
                    </div>
                    <div>{order.date}</div>
                </div>
                <div><span>•</span>{info.time.time_start}- {info.time.time_end}</div>
                <div>{canceled() ? <i style={{color:'red'}}>Скасовано</i> : <i>Безготівкова</i>}</div>
            </div>
        </section>
    )
}

export default Orders;