import React from 'react';
import {Link} from 'react-router-dom'

const Success = () => {
    return (
        <section className="body body_nonscroll">
            <section className="check_order_confirmation">
                <div className="confirmation_text">
                    <h1>Заброньовано</h1>
                      <p>Ваше замовлення заброньоване та оплачене.<br/><br/> 
                        У разі необхідності наш оператор зв`яжеться з Вами.<br/><br/>
                        Для перегляду та редагування Ваших бронювань, перейдіть у розділ "Бронювання"
                     </p>
                </div>
                <Link to="/search" className="contine" style={{textDecoration:'none'}}> ok </Link>
            </section>
        </section>
    );
};

export default Success;