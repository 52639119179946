import React from 'react';
import BackToProfile from './BackToProfile';
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';

const Conditions = ({documents}) => {
    const pageName ='Юридична інформація'
    const clickAgreement =(event)=>{
        event.preventDefault();
        console.log(event.target.href)
    }
    return (
        <motion.div className='info personInfo'
        initial ={{scale:0.6}}
        animate={{scale:1}}
        exit ={{opacity:0}}>
            <BackToProfile pageName={pageName}/>
            <div className="info">
            {
                documents.map(doc=>{
                    return(
                    <div className="frame frame_bt" key={doc.url}>
                        <div className="AgreementInfo">
                            <div>
                                <Link to={doc.url}> {doc.name}</Link>
                            </div>
                        </div>
                    </div>
                    )
                })
            }
            </div>
        </motion.div>
    );
};

export default Conditions;