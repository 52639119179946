import React, {Suspense } from 'react'
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import store from './store/store'
import App from './App';
import './i18n';
import { BrowserRouter } from "react-router-dom";
import Waiting from './Components/UI/Waiting';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  <BrowserRouter>
    <Suspense fallback={<Waiting/>}>
    <App/>
    </Suspense>
  </BrowserRouter>
  </Provider>
);

/*
ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
    <Suspense fallback={<div>Loading... </div>}>
    <App/>
    </Suspense>
  </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
*/