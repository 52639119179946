import React from "react";
import {Link} from "react-router-dom";


const BackToProfile=({pageName, url})=>{
    return ( 
    <div className="header_tt frame frame_bt">
        <div className="back_to_profile">
            <Link to={url? url :"/profile"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.991 511.991"><path d="M153.433 255.991L381.037 18.033c4.063-4.26 3.917-11.01-.333-15.083-4.229-4.073-10.979-3.896-15.083.333L130.954 248.616c-3.937 4.125-3.937 10.625 0 14.75L365.621 508.7a10.65 10.65 0 007.708 3.292c2.646 0 5.313-.979 7.375-2.958 4.25-4.073 4.396-10.823.333-15.083l-227.604-237.96z"></path></svg>
            </Link>
        </div>
        <div>{pageName}</div>
        <div></div>
    </div>
    )
}

export default BackToProfile