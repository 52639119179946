import axios from 'axios';
import {getToken} from '../authMethods'
import config from '../config.js'

export default class PostService{
    static api = config.api.url
    static async putResponce(apiUrl, body){
        const token = getToken()
        const url = this.api + apiUrl
        //console.log('AXIOS params', params)
        return axios.put(url, body,
            {headers: {token: token}}
        ).then((responce) => {
            console.log('responce', responce)
            return responce.data
        }).catch((err)=>{
            console.log(err)
            return null
        })
    }
    static async cancelOrder(orderId){ 
        const apiUrl = 'c_order_cancel_by_client/'+orderId;
        const responce = await this.putResponce(apiUrl)
        console.log('postOrder', responce)
        return responce
    }
}