import React from 'react';
import { func_round } from '../../js/function';

const Discount = ({club}) => {
    const value = club.discount ? club.discount.value >0 ? func_round(club.discount.value*100) : null : null
    return (
        value ? <div className="discount"><div><span> - {value} %</span></div></div> : ''
    );
};

export default Discount;