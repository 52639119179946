import axios from 'axios';
import {func_dateToString} from '../js/function';
import { compose } from '../js/function';
import {getToken} from '../authMethods';
import config from '../config.js';
import {Logout} from  '../authMethods'


export default class GetService{
    static api = config.api.url
    static async getResponce(apiUrl, params){
        const token = getToken()
        const url = this.api + apiUrl
        //console.log('AXIOS params', params)
        return axios.get(
            url,  
            {
                headers: {token: token},
                params
            }
        ).then((responce) => {
            return responce.data
        }).catch((err)=>{ 
            console.log('err', err)
            if(err?.response?.status === 401){ 
                Logout()
            }
            return  null
        })
    }
    static async getClubInfo(clubId,filter){ 
        const apiUrl = 'mobile_get_club_info_filter/'+clubId;
        const responce = await this.getResponce(apiUrl, getParamsFilter(filter))
        return responce?.[0]
    }
    static async getClubInfoMS(clubId){
        const apiUrl = 'mobile_get_club_info_withoutMS/'+clubId;
        const responce = await this.getResponce(apiUrl)
        return responce?.[0]
    }
    static async getClubList(filter){
        const apiUrl = 'mobile_get_club_list_filter';
        return await this.getResponce(apiUrl, getParamsFilter(filter))
    }
    static async getMyClubList(filter){
        const apiUrl = 'mobile_get_my_club_list';
        return await this.getResponce(apiUrl)
    }
    static async getOrderById(orderId){
        const apiUrl = 'c_get_orderById/'+orderId
        const responce = await this.getResponce(apiUrl)
        return responce
    }
    static async getSetMyClub(clubId){
        const apiUrl = 'c_get_set_my_club/'+clubId
        const responce = await this.getResponce(apiUrl)
        return responce
    }

    static async getClientInfo(){
        const apiUrl = 'mobile_get_client_info'
        const responce = await this.getResponce(apiUrl)
        return responce
    }
    static async getPaymentList(){
        const apiUrl = 'mobile_get_payment_list'
        const responce = await this.getResponce(apiUrl)
        return responce
    }
    static async getOrders(){
        const apiUrl = 'c_get_order_list/'
        return await this.getResponce(apiUrl)
    }
    static async getActions(){
        const apiUrl = 'c_get_action_list';
        return await this.getResponce(apiUrl)
    }
    static async getCityList(){
        const apiUrl = 'c_get_cityList';
        return await this.getResponce(apiUrl)
    }
    static async getPaymentLink(order_id){
        const apiUrl = 'addPayment/'+ order_id ;
        return await this.getResponce(apiUrl)
    }

}

const getParamsFilter =(filter)=> {
    const {y_m_d} = func_dateToString(filter.date)    
    const setDate =()=> {return filter.date ? {datetime: y_m_d(filter.date)}: {}}
    const setTime =(obj)=> {
        return  filter?.time?.time_start 
                ? filter?.time?.time_end 
                ? {...obj,
                    time_start: Math.round(filter.time.time_start)+':00',
                    time_end: Math.round(filter.time.time_end)+':00'
                } : {...obj} : {...obj}
    }
    const setCortType =(obj) =>{
        const getArray =()=>{
            return filter?.cortTypeList 
            ? filter.cortTypeList.filter(f=> f.active === true).map(f=> f.name)
            : null
        }
        return getArray() ? {...obj, cort_type: JSON.stringify(getArray())} :{...obj}
    }
    const setCortCover =(obj)=>{
       const getArray =()=>{
           return filter?.cortCoverList 
           ? filter.cortCoverList.filter(f=> f.active === true & f.name !== 'all').map(f=> f.name)
           : null
       }
       return  getArray() ? getArray().length>0 
        ? {...obj, cort_cover: JSON.stringify(getArray())} 
        :{...obj} : {...obj}
    }
    return filter? compose(setDate, setTime, setCortType, setCortCover)(filter): null
}