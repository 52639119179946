import React, {useRef} from 'react';
import GoogleMapReact from 'google-map-react';
import config from '../../config';
import { useState } from 'react';
import ClubItemMap from '../../Components/ClubList/ClubItemMap'
import {motion} from 'framer-motion'

const ClubListMap = ({clubsFiltered, filter}) => {
    console.log('filter.city',filter.city)
    const [clubChosen, setClubChosen] = useState({})
    const map = 
        filter?.city?.lat && filter?.city?.lng && filter?.city?.id && filter?.city?.zoom >0
        ? clubChosen?.city === filter?.city && clubChosen.lat && clubChosen.lng
        ? {center:{lat:clubChosen.lat, lng: clubChosen.lng}, zoom:12}
        : {center:{lat:filter.city?.lat*1, lng: filter.city?.lng*1}, zoom:filter?.city?.zoom} 
        : clubChosen.lat && clubChosen.lng 
        ? {center:{lat:clubChosen.lat, lng: clubChosen.lng}, zoom:12}
        : {center:config.googleMap.props.center, zoom:config.googleMap.props.zoom}
   
    const getClubArray = (clubChosen, clubsFiltered) =>{
        return clubChosen.id
            ? clubsFiltered.map(c=>{
                return c.id === clubChosen.id ? {...c, acitve:true} :{...c, acitve:false}
               }) 
            : [...clubsFiltered]
    }
    
    const clickTag =(club, i)=> {
        setClubChosen(club)
        //const clubScroll = document.getElementById('clubScroll')
        //clubScroll.scrollLeft = i*300
    }
    const clubArray = getClubArray(clubChosen, clubsFiltered) 
    return (
         // Important! Always set the container height explicitly
    <motion.section className='clubListMap'
        initial ={{scale:0.5}}
        animate={{scale:1}}
        exit ={{opacity:0}}>
        <div className='mapContainer'>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: config.googleMap.key,
                    language: 'uk',
                    region: 'ua',
                    libraries:['places']
                }}
                defaultCenter={config.googleMap.props.center}
                defaultZoom={config.googleMap.props.zoom}
                center ={map.center}
                zoom={map.zoom}
            >
                {clubArray.map((club,i)=>
                <ClubTag
                    lat={club.lat}
                    lng={club.lng}
                    club={club}
                    key={'id'+club.id}
                    i={i}
                    clickTag ={clickTag}
                />
                )}
            
            </GoogleMapReact>
        </div>
        <ClubList clubArray={clubArray} clickTag={clickTag}/>
    </motion.section>
    );
};

const ClubTag = ({club, clickTag}) => {
    const classPopup =()=> club.acitve ? 'popup-bubble popup_checked' : 'popup-bubble'
    return(
        <div className={classPopup()} onClick={()=>clickTag(club)}><div>від {club.priceAverage}₴</div></div>
    )
}

const ClubList =({clubArray, clickTag})=>{
    const elementWidth =300;
    const scrollValue =()=> clubArray.map(c => c.acitve ? true : false).indexOf(true)*elementWidth
    const smoothScroll =(container, scrollTo)=>{
        const itaration = 100
        const scrollStart = container.scrollLeft
        const shift =()=> (scrollTo-scrollStart)/itaration
        for (let i=1; i<=itaration; i++){
            setTimeout(()=>{
                container.scrollLeft = scrollStart + shift()*i
            },i*2)
        }
        return(container)
    }
    const myRef = useRef(null)
    const executeScroll = () => myRef.current ? smoothScroll(myRef.current, scrollValue()) : null
    console.log(executeScroll())
    return(
        <section className="MapClubs">
            <div className='clubScroll' ref={myRef}>
                <div>
                    {clubArray.map((club,i)=><ClubItemMap key={'id_'+club.id} club={club} i={i} clickTag={clickTag}/>)}
                </div>
            </div>

        </section>
    )
}



export default ClubListMap;