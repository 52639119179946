/******************************** ОПРЕДЕЛЕНИЕ ОСНОВНЫХ КОНФИГУРАЦИОННЫХ ДАННЫХ  **************************/
// set PUBLIC_URL=https://tennis.book4sport.com &&npm run build
// set PUBLIC_URL=https://dev.tennis.book4sport.com &&npm run build

// const config ={
// 	api:{
// 		url:"https://dev2api.book4sport.com/"
// 	},
// 	url:"http://dev.tennis.book4sport.com",
// 	basePath:"/",
// 	payment:{
// 		success:"http://dev.tennis.book4sport.com/payment/success",
// 		error:"http://dev.tennis.book4sport.com/payment/error"
// 	},
// 	googleMap:{
// 		key:'AIzaSyB86TI-wiJJTYXThoRIVO8xvQU7Fc2j_aE',
// 		props:{
// 			center: {lat: 49.40701241057798, lng: 28.997983195909566},
// 			zoom: 5
// 		}
// 	}
// }

const config ={
	api:{
		url:"https://tennis.api.book4sport.com/"
	},
	url:"http://tennis.book4sport.com",
	basePath:"/",
	payment:{
		success:"http://tennis.book4sport.com/payment/success",
		error:"http://tennis.book4sport.com/payment/error"
	},
	googleMap:{
		key:'AIzaSyB86TI-wiJJTYXThoRIVO8xvQU7Fc2j_aE',
		props:{
			center: {lat: 49.40701241057798, lng: 28.997983195909566},
			zoom: 5
		}
	}
}


// const config ={
// 	api:{
// 		url:"http://localhost:3334/"
// 	},
// 	url:"http://localhost:3000",
// 	basePath:"/",
// 	payment:{
// 		success:"http://dev.tennis.book4sport.com/payment/success.html",
// 		error:"http://dev.tennis.book4sport.com/payment/error.html"
// 	},
// 	googleMap:{
// 		key:'AIzaSyB86TI-wiJJTYXThoRIVO8xvQU7Fc2j_aE',
// 		props:{
// 			center: {lat: 49.40701241057798, lng: 28.997983195909566},
// 			zoom: 5
// 		}
// 	}
// }

export default config