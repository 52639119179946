import React from 'react';
import FilterBigEl from '../UI/FilterBigEl';

const FilterBlock = ({newFilter, getCortCover, getCortType}) => {
    const amount =(arr)=> arr.map(c=> c.active ? 1 : 0 ).reduce((s,c)=> s+c)
    const cortType  = [...newFilter.cortTypeList]
    const cortCover = [...newFilter.cortCoverList]
    const clickCortType = (el) =>{
        const newCortType =()=> cortType.map(c=> {
           return c.id === el.id 
            ? c.active ? amount(cortType) > 1 
            ? {...c, active:false} : {...c}
            : {...c, active:true} 
            : {...c}
        })
        return amount(newCortType())>0 ? getCortType(newCortType()) : null
    }
    const clickCortCover = (el) =>{
        const isAll  =()=> cortCover.map(c=> c.name==='all' & c.active === true ? 1 : 0 ).reduce((s,c)=> s+c)
        const setCoverAll =()=> cortCover.map(c=> c.name === 'all' ? {...c, active:true} : {...c, active:false})
        const setOneEl = () => cortCover.map(c=> c.id === el.id ? {...c, active:true} : {...c, active:false})
        const setAddChangeEl = () => cortCover.map(c=>{
            return c.id === el.id 
                ? c.active ? {...c, active:false} : {...c, active:true}
                : {...c}
        })
        const setAddEl = () => cortCover.map(c=> c.id === el.id ? {...c, active:true} :{...c})
        const newCortType =()=>{
            return el.name ==='all' 
                ? setCoverAll() : isAll() === 1 
                ? setOneEl() : amount(cortCover) <= 1
                ? setAddEl() : (cortCover.length > (amount(cortCover)+2)) 
                ? setAddChangeEl() : setCoverAll()
        }
        return amount(newCortType())> 0 ? getCortCover(newCortType()) : null
    }
    return (
     <div>
        <div className="filter frame frame_bt cort_type" data-name="cort_type">
            <div className="title">Тип корта</div>
            <div className="filter_type1">
                {cortType.map(el=><FilterBigEl click={clickCortType} key={el.name} el={el}/>)}
            </div>
        </div>
        <div className="filter frame frame_bt cort_cover" data-name="cort_cover">
            <div className="title">Покриття</div>
            <div className="filter_type2">
                {cortCover.map(el=><FilterBigEl click={clickCortCover}  key={el.name} el={el}/>)}
            </div>
        </div>
            
     </div>
    );
};

export default FilterBlock;