import '../../css/addService.css';

import React from 'react';
import { useGetOrder } from '../../hooks/useOrderObject';
import AddServiceBlock from './AddService/AddServiceBlock';
import { useState } from 'react';
import {useDispatch } from 'react-redux'
import { setOrderData} from '../../store/orderSlice'
import { useNavigate, useLocation} from 'react-router-dom';
import {getUpperLocation} from '../../js/function'
import { useEffect } from 'react';
import { motion } from 'framer-motion';


const ClubInfoAddService = ({club, url}) => {
   
    const discount = club?.discount?.value ? club.discount?.add_service ?  club.discount.add_service  :  club?.discount.value : 0
    const services = club?.services
    const order = useGetOrder()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    let from = location
    //console.log('ClubInfoAddService useLocation', from, getUpperLocation(location.pathname))
    const add_service =()=>  order?.add_service 
        ? [...order.add_service] : [] 
    const [addService, setAddService] = useState(add_service())
    //console.log('addService', addService)
    const pushAddService =(item)=>{
        const include =()=> order?.add_service 
            ? order.add_service.filter(s=> s.id === item.id).length>0 
            ? true : null : null
        const push =()=> order.add_service ? [...order.add_service, item] : [...[],item] 
        const del =()=> order.add_service ? order.add_service.filter(s=> s.id !== item.id) : null
        const new_add_service =()=> include() ? del() : push()
        const newOrder =()=> new_add_service() ? {...order, add_service: new_add_service()} : null
        const execute =() => newOrder() ? dispatch(setOrderData(newOrder())) : null
        setAddService(add_service())
        execute()
    }
    useEffect(()=>{
        (()=> order.time.timeStart ? null :  navigate(getUpperLocation(location.pathname)))()
    },[])

    return (
            <motion.section
                className="addService info"
                initial ={{scale:0.6}}
                animate={{scale:1}}
                exit ={{opacity:0}}
            >
                <div className="header_tt frame frame_bt">
                    <div className="back_to_club" onClick={()=>url?.back ? navigate(url.back) : null}>
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.991 511.991"><path d="M153.433 255.991L381.037 18.033c4.063-4.26 3.917-11.01-.333-15.083-4.229-4.073-10.979-3.896-15.083.333L130.954 248.616c-3.937 4.125-3.937 10.625 0 14.75L365.621 508.7a10.65 10.65 0 007.708 3.292c2.646 0 5.313-.979 7.375-2.958 4.25-4.073 4.396-10.823.333-15.083l-227.604-237.96z"></path></svg>           
                     </div>
                    <div>Додаткові послуги</div>
                    <div></div>
                </div>
                {services ? <AddServiceBlockList services={services} discount={discount} pushAddService={pushAddService}/> : <AddServiceNone/> }
        </motion.section>
    );
};

const AddServiceBlockList =({services, discount, pushAddService})=>{
    return(
        <div className="serviceList">
            {services.map(service=><AddServiceBlock key={service.name} discount={discount} service={service} pushAddService={pushAddService}/>)}     
        </div>
    )
}

const AddServiceNone =()=>{
    return(
        <div className="serviceList">
             Не має додаткових послуг 
        </div>
    )
}


export default ClubInfoAddService;