import {compose, func_getTimeString} from '../js/function';
import  {useGetOrder} from './useOrderObject';

export const useOrderRecalc =() =>{
    const getObj = useGetOrder()
    return calculate(getObj)
}
export const orderCalc=(order)=>{
    return calculate(order)
}

const calculate=(order)=>{
    const getTimeString =(obj)=>{
        const time =()=> obj?.time
        const newTime =(time)=> time?.timeEnd - time?.timeStart > 0 
            ?{...time,
                 time_start : func_getTimeString(time.timeStart), 
                 time_end : func_getTimeString(time.timeEnd),
                 timeStart: time.timeStart,
                 timeEnd: time.timeEnd
            }
            :{...time}
        return {...obj, time:newTime(time())}
    }
    const getDuration=(obj)=>{
        return obj.time?.timeEnd ? obj.time?.timeStart 
            ? {...obj, duration: obj.time?.timeEnd - obj.time?.timeStart}
            : {...obj, duration: null}
            : {...obj, duration: null}
    }
    const getPriceAddItem =(obj)=>{
        const add_service =()=> obj?.add_service
        const setSum =(add_service)=> add_service.map(s=>{
            return  s.price_unit ==='per_hour' 
                    ? {...s, sum:s.price*obj.duration} 
                    : {...s, sum:s.price}
        })
        return add_service() ? {...obj, add_service: setSum(add_service())} : {...obj}
    }
    const getPriceAdd =(obj)=>{
       const discount =()=> obj?.discount ?
                         obj.discount.add_service ?  obj.discount.add_service :  obj.discount.value
                         : 0
       const sumAddService =(add_service)=>{
           return add_service.reduce((sum,item)=> sum = sum + item.sum, 0)
        }
        return obj.add_service ? {...obj,
                 priceService:sumAddService(obj.add_service),
                 costService: Math.round(100*(sumAddService(obj.add_service) * (1-discount())))/100
            } : {...obj, priceService:0}
    }
    const getPriceTime =(obj)=>{return {...obj, priceTime: obj.time?.price}}
    const getCostTime = (obj) => {
        const discount =()=> obj?.discount?.value ? obj.discount.value : 0
        return {...obj, costTime: Math.round(100* obj.priceTime *(1-discount()))/100}
    }
    const getPrice =(obj) => {return{...obj, price: obj.priceService + obj.priceTime}}
    const getCost =(obj) => {
        const addServiceCost = ()=> obj.costService ? obj.costService : 0
        return{...obj, cost: Math.round(100*(obj.costTime + addServiceCost()))/100}
    }
    const getAmount=(obj)=>{
        return {...obj, amount: obj.cost, discountCalc: Math.round(100*(obj.price - obj.cost)/obj.price)}
    }
    
    return compose(
            getTimeString,
            getDuration,
            getPriceAddItem,
            getPriceAdd,
            getPriceTime,
            getCostTime,
            getPrice,
            getCost,
            getAmount)(order)
}