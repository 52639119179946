import { createSlice } from '@reduxjs/toolkit'

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    address:null,
    text:null,
    tel:null,
    map:null,
    club_main_service_id:null,
    date: null,
    discount: null,
    add_service:null,
    time:{
      timeStart: null,
      timeEnd: null
    }
  },
  reducers: {
    setOrderData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //console.log('reducers increment', state, action)
      state.text =action.payload?.text
      state.address = action.payload?.address
      state.map = action.payload?.map
      state.club_main_service_id = action.payload?.club_main_service_id
      state.date = action.payload?.date
      state.discount = action.payload?.discount
      state.add_service = action.payload?.add_service
      state.cort_cover= action.payload?.cort_cover
      state.cort_type = action.payload?.cort_type
      state.time = action.payload?.time ? {...action.payload.time} : null
    },
    delOrderData: (state) => {
      state.club_main_service_id = null
      state.date = null
      state.time = null
    }
  },
})

// Action creators are generated for each case reducer function
export const { setOrderData, delOrderData } = orderSlice.actions

export default orderSlice.reducer