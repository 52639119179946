import '../../css/order.css';
import {React, useEffect} from 'react';
import { useOrderRecalc } from '../../hooks/useOrderRecalc';
import OrderInfo from '../Order/OrderInfo';
import {getUpperLocation} from '../../js/function'
import { useNavigate, useLocation} from 'react-router-dom';
import {motion} from 'framer-motion'

const ClubInfoConfirm = ({club, url, setOrderError}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const order = useOrderRecalc()
    useEffect(()=>{
        (()=> order.time.timeStart ? null :  navigate(getUpperLocation(location.pathname)))()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <motion.section
            className="check_order info"
            initial ={{scale:0.6}}
            animate={{scale:1}}
            exit ={{opacity:0}}
        >
            <OrderInfo order={order} club={club} url={url}/>
        </motion.section>
    );
};


export default ClubInfoConfirm;