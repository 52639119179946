import '../../css/club.css';

import React, { useState, useEffect, useContext} from 'react';
import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import { useLocation, useParams } from 'react-router';
import ClubInfo from '../../Components/Club/ClubInfo';
import ClubInfoTime from '../../Components/Club/ClubInfoTime';
import ClubInfoAddService from '../../Components/Club/ClubInfoAddService';
import GetService from '../../API/getService';
import ClubInfoConfirm from '../../Components/Club/ClubInfoConfirm';
import Waiting from '../../Components/UI/Waiting'
import {Continue} from '../../Components/Order/OrderPanal'
import {AuthContext } from '../../context'
import {useSetDistance} from '../../hooks/useFilter'
import {OrderError} from '../../Components/UI/Popup'


const RootClub = ({filter, openFilter}) => {
   const navigate = useNavigate()
   const {geo} = useContext(AuthContext) 
   const setDistance = useSetDistance(geo)
   const [orderErr, setOrderError] = useState(false)  
   const parseId =(str)=>  str.split('_')[1] ? str.split('_')[1] :navigate('/search')
   
   const {clubUrl} = useParams(); 
   
   const clubId = parseId(clubUrl)
   const [clubInfo, setClubInfo] = useState()
   useEffect(()=>{
      fetchClubInfo(clubId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   },[])
   useEffect(()=>{
      fetchClubInfo(clubId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   },[filter.date, filter.time.time_start, filter.time.time_end])
   const fetchClubInfo = async() => {
         let responce =  await GetService.getClubInfo(clubId,filter)
         responce = responce ? responce : await GetService.getClubInfoMS(clubId)
         responce = setDistance(responce)
         setClubInfo(responce)
   }
   const location = useLocation()
   const printClub =()=>{
      return(
            <section className= 'contentWrapper hiddenMenu'>
            <main className="club timeOrder">
            
               <Routes location={location} key={location.path}>
                  <Route path="/" element={<ClubInfo club={clubInfo} filter={filter} openFilter={openFilter} url={{back:'../', forward:'./time'}}/>}/>
                  <Route path="/time" element={<ClubInfoTime club={clubInfo} filter={filter} url={{back:'../', forward:'../addService'}}/>}/>
                  <Route path="/addService" element={<ClubInfoAddService club={clubInfo} url={{back:'../time', forward:'../confirm'}}/>}/>
                  <Route path="/confirm" element={<ClubInfoConfirm club={clubInfo} url={{back:'../addService', forward:null}}/>}/>
               </Routes>
            
               
               <Continue club={clubInfo}  setOrderError={setOrderError}/>
            </main>
            {orderErr ? <OrderError closeOrderErr={setOrderError}/>: ''}
            </section>
      )
   }
   const printClubUnavailable =() =>{
      return(
         <section className= 'contentWrapper'>
            <main className="club">
            <Routes>
               <Route path="*" element={<Navigate to="./" replace />}/>
               <Route path="/" element={<ClubInfo club={clubInfo} filter={filter} openFilter={openFilter} url={{back:'../', forward:'./time'}}/>}/>
            </Routes>
            </main>
         </section>
      )
   }
   const printClubWait =()=>{
      return(
         <Waiting/>
      )
   }
   //console.log('clubInfo', clubInfo)
  
   return   clubInfo ? clubInfo.main_service 
            ? printClub() : printClubUnavailable()
            : printClubWait()
};

export default RootClub;