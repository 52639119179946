import React from 'react';

const FilterCityList = ({newFilter, getCityList}) => {
    const cityList = {...newFilter}.cityList
   
    const checkClick=(c, i)=>{
        const cities = cityList.map(city => city.id === c.id ? {...city,active:true} : {...city,active:false})
        getCityList(cities,  c)
    }
    return (
    <div className="filter frame frame_bt" id="city_list">
        <div className="title">Місто</div>
        <div className="filter_type3">
            {cityList.map((c,i)=>{
                return( 
                <div className={c.active === true ? "btn_w checked" : "btn_w"} key={c.name} onClick={()=>checkClick(c,i)}>
                    <span>{c.text}</span> 
                </div>
                )
            })}
        </div>
    </div>
    );
};

export default FilterCityList;