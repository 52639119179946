import React,{useState} from 'react';
import {motion} from 'framer-motion'
import PutService from '../../API/putService';

export const PopupCancelOrder = ({setCancelPopup, orderId, setCancelOrder}) => {
    console.log('orderId', orderId)
    const closePopup =()=>{setCancelPopup(false)}
    const [visabilty, setVisability] = useState('visible')
    const [result, setResult] = useState(false)
    
    const errMsg ='Не вдалося відмініти заказ спробуйте пізнише'
    const successMsg ='Заказ скасовано'
    const [msg, setMsg] = useState(errMsg)
    const fetchData =async(orderId)=>{
         setVisability('hidden')
         const responce = await PutService.cancelOrder(orderId)
         responce 
         ? (()=>{
            setResult(true)
            setMsg(successMsg)
            setCancelOrder(true)
         })()
         : (()=>{
            setResult(true)
            setMsg(errMsg)
         })()
         console.log('responce', responce)
         
    } 
    const cancel =()=>fetchData(orderId)
    const closeMsg =()=>{
        setCancelPopup(false)
        setResult(false)
    }
    return (
        <motion.section className="msg" id="msg" style={{zIndex: 100}}
            initial ={{opacity:0}}
            animate={{opacity:1}}
            exit ={{opacity:0}}>
            <div className="overlay" onClick ={closePopup}></div>
            <div className="confirmMsg" style={{display:'block'}}>
                {!result ? 
                <>
                <div className="respondWaiting" style={{display:'block'}}>
                    <img src="/img/icon/spinner.gif" alt="spin"/>
                </div>
                <div className="chooseRefund" style={{visibility:visabilty}}>
                    <p>Ви впевнені, що хочете скасувати замовлення?</p>
                    <div className="choose">
                        <div onClick ={cancel} className="btn_w_g" data-name="yes"><span>так</span></div>
                        <div onClick ={closePopup} className="btn_w_g" data-name="no"><span>ні</span></div>
                    </div>
                </div>
                </>
                : <ShowMsg close={closeMsg} msg={msg}/>
                }
            </div>
	    </motion.section>
    );
};

export const OrderError=({closeOrderErr})=>{
    const closeMsg=()=>closeOrderErr(false)
    const msg ='Резерв не поставлений. Обраний час вже зайнято. Спробуйте обрати новий час'
    return(
        <motion.section className="msg" id="msg" style={{zIndex: 100}}
            initial ={{opacity:0}}
            animate={{opacity:1}}
            exit ={{opacity:0}}>
            <div className="overlay" onClick ={closeMsg}></div>
            <div className="confirmMsg" style={{display:'block'}}>
             <ShowMsg close={closeMsg} msg={msg}/>
            </div>
	    </motion.section>
    )
}


export const ShowMsg =({close, msg})=>{
    return(
     <div className="chooseRefund">
        <div>{msg}</div>
        <div className="choose">
            <div onClick ={close} className="btn_w_g" data-name="yes"><span>ok</span></div>
        </div>
    </div>
    )
}




