import axios from 'axios';
import {getToken} from '../authMethods'
import config from '../config.js'

export default class PostService{
    static api = config.api.url
    static async postResponce(apiUrl, body){
        const token = getToken()
        const url = this.api + apiUrl
        //console.log('AXIOS params', params)
        return axios.post(url, body,
            {headers: {token: token}}
        ).then((responce) => {
            console.log('responce', responce)
            return responce.data
        }).catch((err)=>{
            console.log(err)
            return null
        })
    }
    static async postOrder(order){ 
        const apiUrl = 'c_POST_time_reserv_by_client/';
        const responce = await this.postResponce(apiUrl, order)
        console.log('postOrder', responce)
        return responce
    }
}