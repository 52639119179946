import '../../css/vanilla-calendar.css';

import React, {useState} from 'react';
import FilterCityList from './FilterCityList';
import {checkedFilter} from './InitFilter';
import Calendar from 'react-calendar';
import FilterTimeChoice from './FilterTimeChoice';
import FilterBlock from './FilterBlock';
import 'react-calendar/dist/Calendar.css';
import {motion} from 'framer-motion'

const FilterPanel = ({filter, htmlControl, closeFilter, updateFilter}) => {
    const active = {...htmlControl}.filter
    return (
        active 
        ? active === 'city' || active==='corts' 
            ? <PrintBlindFilter 
                filter={filter}
                active={active}
                closeFilter={closeFilter}
                updateFilter={updateFilter}
                 /> 
        : active === 'date' || active==='time'  
            ?  <PrintSlideFilter 
                filter={filter}
                active={active}
                closeFilter={closeFilter}
                updateFilter ={updateFilter}
                />
        : <HideFilter/> : <HideFilter/>
    )
};
const PrintBlindFilter =({filter, active, closeFilter, updateFilter})=>{

    const [newFilter, setNewFilter]=useState(filter)
    const getCityList  =(cityList, city)=> setNewFilter({...newFilter, cityList:cityList, city:city})
    const getCortCover =(cover)=> setNewFilter({...newFilter, cortCoverList: cover})
    const getCortType =(type)=> setNewFilter({...newFilter, cortTypeList: type})

    const applyFilter =()=>{ 
        updateFilter(newFilter)
        closeFilter()
    }
    return (
    <motion.section 
        className="selection_filters"
        initial ={{height:"0%"}}
        animate={{height:"100%"}}
        exit ={{height:"0%"}}
    >
    <section className="filters">
        <section className="filter_list filterContainer">
            {active==='city' 
            ? <FilterCityList newFilter={newFilter} getCityList={getCityList}/>
            : <FilterBlock newFilter={newFilter} getCortCover={getCortCover} getCortType={getCortType}/>
            }
        </section>
        <section className="filtrt_header frame frame_bt">
            <div className="filters_close" onClick={closeFilter}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001"><path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path></svg>            </div>
            <div className="name"><span>фільтр</span></div>
            <div></div>
        </section>
        <div className="filters_confirm" onClick={applyFilter}><span>Застосувати</span></div>
    </section>
    </motion.section>
    )
}
const PrintSlideFilter = ({filter, active, closeFilter, updateFilter}) => {
    const [newFilter, setNewFilter] = useState(filter)
    const [value, onChange] = useState(filter.date);
    
    const setStart = (start) => {
        updateFilter(checkedFilter({...newFilter, date:value}))
        setNewFilter({...newFilter,
             time:{...newFilter.time, time_start:start} ,
             selected:{...newFilter.selected, time_start:start}
        })
    }
    const setEnd   = (end) => {
        setNewFilter({...newFilter,
            time:{...newFilter.time, time_end:end},
            selected:{...newFilter.selected, time_end:end}
        })
    }

    const applyFilter =()=>{
        updateFilter(checkedFilter({...newFilter, date:value}))
        closeFilter()
    }
    
    return (
    <section className="selection selectionActive">
        <div className="overlay" onClick={closeFilter}></div>
        <motion.div 
            className="filter_selection"
            initial ={{top:"100%"}}
            animate={{top:"20%"}}
            exit ={{top:"100%"}}
        >
            <div className="close" onClick={closeFilter}> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001"><path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path></svg>
            </div>
            <div className="filter_area">
                <div>
                    <div className="filter_content" id="filterContentArea">
                    { active === 'date' 
                        ? <Calendar 
                            onChange={onChange}
                            value={value}
                            minDate={new Date()}
                            locale="ukr"
                          />
                        : <FilterTimeChoice newFilter ={newFilter} setStart={setStart} setEnd={setEnd}/>
                    }
                    </div>
                </div>
            </div>
            <div className="confirm" onClick={applyFilter}>
                <div className="checked"><span>Продовжити</span></div>
            </div>
        </motion.div>
    </section>
    );
}
const HideFilter =()=>{
    return <span></span>
}

export default FilterPanel;