import React from 'react';
import {Link} from 'react-router-dom'

const Error = () => {
    return (
        <section className="body body_nonscroll">
            <section className="check_order_confirmation">
                <div className="confirmation_text">
                <h1> Невдала оплата </h1> 
                    <p> В процесі оплати сталася помилка.</p>
                </div>
                <Link to="/search" className="contine" style={{textDecoration:'none'}}> ok </Link>
            </section>
        </section>
    );
};

export default Error;