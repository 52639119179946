import {compose, func_getTimeString} from '../js/function';
import store from '../store/store'

const getTimeArray =(min, max, step)=>{
    const interval = () => (max - min)/step
    const length =(interval)=> interval > 0 ? Math.ceil(interval) : 1
    const newTimeArray =(length)=> Array.from({length:length}, (a,i)=>
        {return {timeStart: min + i*step, timeEnd: min + i*step + step}}
    )
    return compose(interval, length, newTimeArray)()          
}
export const useGetTimeTable =(club, typeValue) => {
    try{
    const main_service = [...club.main_service]
    const timeStep = 0.5 // 1, 0.5 , 0.25 , 0.1 
    const getTimeTable = (service)=>{
        const filterTimeTable =()=> main_service.filter(ms=> ms.cort_type_name === service.cort_type_name)
        const getAllInt = (msFiltered) => 
           msFiltered.reduce(
            (accumulator, currentValue) =>
             accumulator.concat(currentValue.time_schedule)
            , [])
          
        const getMinMax = (allInt) => {
            return {
                min: Math.floor(Math.min.apply(Math, allInt.map(a=> a.timeStart))),
                max: Math.ceil(Math.max.apply(Math, allInt.map(a=>a.timeEnd)))
            }
        }
        const setTimeArray =(minMax)=>{
            return getTimeArray(minMax.min, minMax.max, timeStep)
        }
        const multiple =(time)=>{
            const checkMs = (t) => service.time_schedule.some(el=> el.timeStart === t.timeStart)
            const setPrice = (t) => service.time_schedule
                .filter(ts=> ts.timeStart === t.timeStart)
                .reduce((a,c)=> a + c.price, 0)
            return time.map(t=>{
                return {...t
                         ,available:checkMs(t)
                         ,price: setPrice(t)>0 ? setPrice(t)*timeStep : null
                         ,time_start: func_getTimeString(t.timeStart)
                         ,time_end: func_getTimeString(t.timeEnd)
                         ,order:false
                        }
            })
        }
        const checkOrderIntrval =(array)=>{
            const getStore = ()=> store.getState()?.order
            const getOrderTime =() => getStore()?.time
            const checkDate = () =>getStore()?.date === club?.date
            const checkMsId = () =>getStore()?.club_main_service_id === service.club_main_service_id
            const checkOrderTime = () => getOrderTime()?.timeStart ? getOrderTime()?.timeEnd
                    ? getOrderTime().timeEnd - getOrderTime().timeStart > 0 : null : null
            const checkInterval =(a)=> a.timeStart >= getOrderTime().timeStart & a.timeEnd <= getOrderTime().timeEnd
            const applyOrder =(array)=>  {
                return array.map(a => checkInterval(a) ? {...a, order:true} : {...a})
            }

            const getOrderedArray =()=> checkDate() ? checkMsId () ? checkOrderTime() 
                    ? applyOrder(array) : array : array : array
            
            return getOrderedArray()
        } 
        const setTimeTable =(array) =>{
            const min = () => array[0].timeStart
            const max = () => array[array.length-1].timeEnd
            const getPrice =(el) => {
               return  el.sub.some(s=> s.price == null) ? null : el.sub.reduce((a,c)=> a+c.price, 0)
            }
            const newArray = ()=> getTimeArray(min(), max(), 1)
            const getSubList = (el)=> array.filter(
                    a=> a.timeStart >= el.timeStart & a.timeEnd <= el.timeEnd
                )
            const subArray = (newArray)=> newArray.map(el=> {return {...el, sub:getSubList(el)}})
        
            const precise =(newArray) => newArray.map(el=>{
                return {...el
                        ,price: getPrice(el)
                }
            })

            return (compose(newArray, subArray, precise)())
        }
        
        return compose(filterTimeTable, getAllInt, getMinMax, setTimeArray, multiple, checkOrderIntrval, setTimeTable)()
    }

    return   main_service
            .map( ms=> { return {...ms, timeTable :getTimeTable(ms)}})
            .filter(ms=> ms.cort_type_name === typeValue)
    }catch(err){
        return null
    }
}