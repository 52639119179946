import React from 'react';
import { useTranslation } from 'react-i18next';
//import { useLinkClickHandler } from 'react-router-dom';

const LangChange = () => {
    const {/* t*/ i18n } = useTranslation();
    const langList =[
        {lang:'uk', active:true},
        {lang:'ru', active:false},
        {lang:'en', active:false}
    ]
    const changeLanguage =(e)=>{
        console.log('changeLanguage', e.target.value)
        i18n.changeLanguage(e.target.value)
        window.location.reload()        
    }
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng
    return (
        <div>
            <select onChange ={changeLanguage} value ={getLanguage()}>
               {langList.map(l => 
                <option 
                    value ={l.lang}
                    //selected = {l.lang == getLanguage() ? 'selected' : ''}
                    key={l.lang}
                >
                    {l.lang}
                </option>)}
            </select>
        </div>
    );
};

export default LangChange;