import React, { useState } from 'react';
import {func_checkBirthDate} from '../../js/function' 
import {postUserInfoAuth} from '../../hooks/GetUserInfo'
import BackToProfile from './BackToProfile';
import FormSubmitBottom from '../../Components/UI/FormSubmitBottom'
import {motion} from 'framer-motion'

const UserData = ({userInfo}) => {
    
    const cities = userInfo.cityList
    const [info, setInfo] = useState(userInfo.info)
    const [load, setLoad] = useState(false)
    const changInputName =(text)=>setInfo({...info, userName: text})
    const changInputEmail =(text)=>setInfo({...info, email: text})
    const changeBirthDay =(text)=>setInfo({...info, birthday: text})
    const changeCity =(text)=>setInfo({...info, city: text})
    const changeGender =(text) => setInfo({...info, gender: text})
    const onSubmit =async (e)=>{
        setLoad(true)
        e.preventDefault()
        await postUserInfoAuth(info)
        setLoad(false)
    }
    const pageName ='Мої дані'
    return (
        <motion.div className="info personInfo"
        initial ={{scale:0.6}}
        animate={{scale:1}}
        exit ={{opacity:0}}>
            <BackToProfile pageName={pageName}/>
            <div className="profileInfo">
                <form className="profileForm" onSubmit={onSubmit}>
                    <div className="frame">
                        <div className="simple_input">
                            <div>Телефон:</div>
                            <div>
                                <div>
                                    <div className="phoneNumber" style={{height:'20px'}}>
                                        {info.phoneNumber}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame">
                        <div className="simple_input">
                            <div>ім`я:</div>
                            <InputText 
                                placeholder="Введить Ім'я"
                                value ={info.userName}
                                changInput ={changInputName}
                            />
                        </div>
                    </div>
                    <div className="frame">
                        <div className="simple_input">
                            <div>Email:</div>
                            <InputText 
                                placeholder="Введить Email"
                                value ={info.email}
                                changInput ={changInputEmail}
                            />
                        </div>
                    </div>
                    <div className="frame">
                     <div className="simple_input">
                        <div>дата народження:</div>
                        <InputDate value={info.birthday} changeBirthDay={changeBirthDay}/>
                    </div>
                    </div>
                    <div className="frame">
                        <div className="simple_input simple_select">
                            <div>Місто:</div>
                            <InputCities 
                                cities={cities}
                                value ={info.city}
                                changeCity ={changeCity}
                             />
                        </div>
                    </div>
                    <ChekBoxGender
                        value={info.gender}
                        changeGender ={changeGender}
                    />
                    <FormSubmitBottom load={load}/>
                </form>
            </div>
        </motion.div>
    );
};

const InputText =({placeholder, value, changInput})=>{
    const onChange =(e)=>changInput(e.target.value)
    return(
        <div>
            <input onChange={onChange} required="" minLength="5" type="text" placeholder={placeholder} defaultValue={value}/> 
            <svg viewBox="0 0 325 325.375" xmlns="http://www.w3.org/2000/svg"><path d="M114.688 284.676l-73.801-73.801 178.5-178.5 73.8 73.8zm-80.7-60.801l67.7 67.7-101.5 33.8zm281.899-140.3l-12.801 12.8-73.899-73.898 12.801-12.801c12.895-12.903 33.805-12.903 46.7 0l27.199 27.199c12.8 12.937 12.8 33.766 0 46.7zm0 0"></path></svg>
        </div>
    )
}

const InputDate =({value, changeBirthDay})=>{
    const currentBirthDay =  func_checkBirthDate(value)
    const onChange = (e)=> {
        try {
            const birthday = e.target.value.split('.').join('/')
            console.log(birthday)
            changeBirthDay(birthday)
        }catch(err){
            changeBirthDay('')
        }
    }
    return(
        <div className="date">
            <input onChange ={onChange} id="birthday" type="text" defaultValue={currentBirthDay}/>
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M448 64h-21.332V21.332C426.668 9.559 417.109 0 405.332 0H384c-11.777 0-21.332 9.559-21.332 21.332V64H149.332V21.332C149.332 9.559 139.777 0 128 0h-21.332C94.891 0 85.332 9.559 85.332 21.332V64H64C28.715 64 0 92.715 0 128v320c0 35.285 28.715 64 64 64h384c35.285 0 64-28.715 64-64V128c0-35.285-28.715-64-64-64zm21.332 384c0 11.754-9.578 21.332-21.332 21.332H64c-11.754 0-21.332-9.578-21.332-21.332V214.187h426.664zm0 0"></path></svg>
        </div>
    )
}
const InputCities =({cities, value, changeCity})=>{
    const onChange =(e)=> changeCity(e.target.value)
    const city = value ? value : 'Another'
    return(
        <div> 
            <select onChange={onChange} defaultValue={city}>
                <option value="Another">Інше місце</option>
                {cities.map(item=><CityOption item={item} key={item.id}/>)}
            </select> 
        </div>
    )
}

const CityOption =({item})=>{
    return  <option value={item.nameUkr}>{item.text}</option>
}
const ChekBoxGender =({value, changeGender})=>{
    const onChange =(e)=> changeGender(e.target.value)
    return(
        <div onChange={onChange} className="radiogroup sex frame frame_bt">
            <div className="wrapper">
                    <input 
                        className="state" 
                        type="radio"
                        name="app" id="male" value="male"
                        defaultChecked = {value === 'male' ? true : false}
                    /> 
                    <label className="label" htmlFor="male"><span className="text">Чоловік</span></label>
            </div>
            <div className="wrapper"> 
                <input 
                    className="state"
                    type="radio" 
                    name="app" id="female" value="female"
                    defaultChecked = {value === 'female' ? true : false}
                    />
                <label className="label" htmlFor="female"><span className="text">Жінка</span></label>
            </div>
        </div>
    )
}

export default UserData;