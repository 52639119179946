import {compose} from '../js/function';
import store from '../store/store'

export const useGetOrder =()=> store.getState()?.order 
export const useOrderObject =(interval, club) => {
    const getOrder = useGetOrder;
    const currentOrder = getOrder();
    const check_same_interval = () =>{
        return getOrder()?.club_main_service_id === interval?.time?.club_main_service_id 
        ? getOrder()?.date === club?.date
        ? getOrder().time.timeEnd - getOrder().time.timeStart > 0 
        ? true : null : null : null
    }
    const setNewOrderTime = (interval, club) =>{
        console.log('setNewOrderTime',club)
        return {
            club_main_service_id: interval?.time?.club_main_service_id,
            date: club?.date,
            discount: club?.discount,
            cort_cover: interval?.time?.cort_cover_name,
            cort_type: interval?.time?.cort_type_name,
            time:   interval?.cell,
            text:   club?.title,
            tel:    club?.tel,
            address: club?.address,
            map:     club?.map
        }
    }
    const changeOrderTime = (interval) =>{
        const orderTimeStart = () => getOrder()?.time?.timeStart
        const orderTimeEnd   = () => getOrder()?.time?.timeEnd
        const isInside = () => orderTimeEnd() - orderTimeStart() > 0 
            ? (interval.cell.timeStart >= orderTimeStart() & interval.cell.timeStart < orderTimeEnd()) ||
                (interval.cell.timeEnd <= orderTimeEnd() & interval.cell.timeEnd > orderTimeStart())
            : null
        const extendTime =() => {
            return { 
                timeStart: Math.min(interval.cell.timeStart, orderTimeStart()),
                timeEnd:  Math.max(interval.cell.timeEnd, orderTimeEnd())
            }
        }
        const scliceTime =()=>{
            const first =()=> { 
                return {
                    timeStart: Math.min(interval.cell.timeStart, orderTimeStart()),
                    timeEnd: Math.max(interval.cell.timeStart, orderTimeStart())
                }
            }
            const second =()=> { 
            return {
            timeStart: Math.min(interval.cell.timeEnd, orderTimeEnd()),
            timeEnd: Math.max(interval.cell.timeEnd, orderTimeEnd())
            }
            }
            const getDuration =(obj)=> obj.timeEnd - obj.timeStart
            return getDuration(second()) > getDuration(first()) ? second() : first()
        }
        const getNewTime = () =>  isInside() ? scliceTime() : extendTime()
        const checkNewTime =(newTime) => {
            const getTime_schedule =() => [...interval.time.time_schedule]
            const getTimeArray =(ts) => ts.filter(i=> i.timeStart >= newTime.timeStart & i.timeEnd <= newTime.timeEnd)
            const getTimeSum = (timeArray) => timeArray.reduce(
                (accumulator, currentValue) =>
                    accumulator = accumulator + (currentValue.timeEnd - currentValue.timeStart)
                , 0) 
            const getTimPrice =(timeArray) => timeArray.reduce(
                (accumulator, currentValue) =>
                    accumulator = accumulator + (currentValue.timeEnd - currentValue.timeStart)*currentValue.price
                , 0)
            const setTimePrice =(price)=> {return{...newTime, price:price}} 
            
            return compose(getTime_schedule, getTimeArray, getTimeSum)() === newTime.timeEnd - newTime.timeStart ?
                    compose(getTime_schedule, getTimeArray, getTimPrice, setTimePrice)() : null
        }
        return checkNewTime(getNewTime()) 
                ? {...currentOrder, time: checkNewTime(getNewTime()) 
                } : null
    }
    return check_same_interval() ? changeOrderTime(interval) : setNewOrderTime(interval, club)
}
