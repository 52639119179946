import React from 'react';
import BackToProfile from './BackToProfile';
import {motion} from 'framer-motion'

const Document = ({name, iframe}) => {
    return (
        <motion.div className='info personInfo profileAgreementInfo '
            initial ={{scale:0.6}}
            animate={{scale:1}}
            exit ={{opacity:0}}>
                <BackToProfile pageName={name} url="/profile/agreements"/>
    
            <div className="iframe"><iframe title={name} src={iframe ? iframe : ''} style={{border: "none"}}></iframe></div>
        </motion.div>
    );
};

export default Document;