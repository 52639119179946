import React from 'react';
import TimeCell from './TimeCell';
import {getCortCover} from '../../Filters/InitFilter';

const TimeChose = ({time, getOrderTime}) => {
    const timeTable = time.timeTable
    return (
    <div className="row">
    <div className="rowName">
        <div>
            <div>{getCortCover(time.cort_cover_name)}</div>
        </div>
    </div>
    <div className="cells">
        <ul>
         { timeTable.map((t,i)=> <TimeCell key={"id"+i} t={t} time ={time} getOrderTime={getOrderTime}/>)}
        </ul>
    </div>
    </div>
    );
};

export default TimeChose;