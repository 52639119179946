import '../../css/order.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import OrderInfo from '../../Components/Order/OrderInfo';
import GetService from '../../API/getService';
import OrderCancel from '../../Components/Order/OrderCancel';
import {motion} from 'framer-motion'
import Waiting from '../../Components/UI/Waiting';
import {PopupCancelOrder} from '../../Components/UI/Popup'

const Order = () => {
    const parseId =(str)=> str.split('_')[1]
    const {orderUrl} = useParams(); 
    //const orderCalc = useOrderCalc();
    const orderId =()=> parseId(orderUrl)
    const [order, setOrder] = useState(null)
    const [load, setLoad] = useState(true)
    const [cancelPopup, setCancelPopup] = useState(false)
    const [cancelOrder, setCancelOrder] = useState(false)
    const cancelClick =()=> setCancelPopup(true)
    
    const fetchOrder = async(orderId) => {
        setLoad(true)
        const orderInfo = await GetService.getOrderById(orderId)
        setOrder(orderInfo)
        setLoad(false)
    }
    useEffect(()=>{
        fetchOrder(orderId())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    console.log('order', order)
    const orderDate = order?.order?.time?.timeStart 
                    ? new Date(order.date)
                    : null
    const orderDateTime =() => orderDate ? orderDate.setHours(order.order.time.timeStart) : null
    orderDateTime()
    const currentDate = new Date()
    const canceled =()=> order?.canceled ? true : cancelOrder ?  true : false
    console.log('canselOrder', canceled())
    const cancel =()=> order?.active 
                        ? order?.canceled 
                        ? false : orderDate > currentDate
                        ? true : false
                        :false
    const url ={ back:"../" }
    return (
        !load ?
        <section className={(()=> cancel() ? 'contentWrapper hiddenMenu' : 'contentWrapper')()}>
        <main>
        <motion.section
            className="check_order info"
            initial ={{scale:0.6}}
            animate={{scale:1}}
            exit ={{opacity:0}}
        >
          {(()=> order 
              ? <OrderInfo info={order} url={url}/> 
              : <OrderEmpty/>)()
          }
          <div className='cancelOrderBlock'>
            {(()=> cancel() && !cancelOrder ?
                <div style={{paddingBottom:'100px', marginTop:"-60px"}} onClick={cancelClick} >
                    <OrderCancel order={order} />
                </div>
                : '')()}
            {canceled() ?
                <div className="frame frame_bt" style={{paddingBottom:'100px', marginTop:"-60px"}}>
                    <b style={{color:'red'}}>Заказ скасовано</b>
                </div> 
                :'' }
        </div>
         </motion.section>
         
        </main>
        
        {cancelPopup ? <PopupCancelOrder setCancelOrder={setCancelOrder} setCancelPopup={setCancelPopup} orderId={order?.id}/>: ''}
        </section>
        : <Waiting/>
    );
};  

const OrderEmpty =()=>{
    return(
        <div>Немає заказу за таким номером</div>
    )
}
export default Order;