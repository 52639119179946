import cityList from '../../json/cityList.json';
import filterList from '../../json/filterList.json';
import {func_dateToString} from '../../js/function';



export const initFilter =(cities)=>{
  console.log('cities',cities)
  const other={
    "id": 0,
    "name": "other",
    "nameUkr": "Other",
    "text": "Інше місце",
    "lat": "50.473998",
    "lng": "30.507506",
    "zoom": 12,
    "active":true
}
  cities = cities ?  [...cities, other ] : cityList
  return{
 
    active:false, 
    searchValue: '',
    cityList:cities,
    cortCoverList: filterList.cort_cover,
    cortTypeList: filterList.cort_type,
    cortTypeValue : 'outdoor',
    date: new Date(),
    time:{
      time_start:getTimeStart(),
      time_end:23
    },
    default:{
      time_start: 7,
      time_end:23
    },
    defaultStrict:{
      time_start: 7,
      time_end:23
    },
    selected:{
      time_start: 7,
      time_end:23
    }
  }

}
  const {d_m_y} = func_dateToString()
  export function getTimeStart(){
    const date = new Date()
    const h = date.getHours()*1+1 
    return h<22 ? h : 22
  }

  

 export const checkedFilter =(filter)=>{
    const filterDate = d_m_y(filter.date)
    const date = d_m_y(new Date())
    const minTime = filterDate === date ? getTimeStart() : filter.selected.time_start
    const minDefTime =filterDate === date ? getTimeStart() : filter.defaultStrict.time_start
    const startTime = minTime > filter.time.time_start ? minTime : filter.selected.time_start
    const endTime = startTime < filter.selected.time_end 
            ? filter.selected.time_end
            : filter.default.time_end 
    return {...filter, time : {time_start: startTime , time_end: endTime}, default:{time_start: minDefTime , time_end:  filter.defaultStrict.time_end}}
  }

  export const getCortType =(name)=> name ? filterList.cort_type.filter(item => item.name === name )[0].original_text : ''
  export const getCortCover = (name)=> name ? filterList.cort_cover.filter(item => item.name === name )[0].original_text : ''


