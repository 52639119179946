import '../../css/payment.css';
import React from 'react';
import BackToProfile from './BackToProfile';
import {motion} from 'framer-motion'


const Payment = ({payments}) => {
    console.log('payments',payments)
    const pageName ='Платежі'
    return (
        <motion.div className="info personInfo"
            initial ={{scale:0.6}}
             animate={{scale:1}}
             exit ={{opacity:0}}>
          <BackToProfile pageName={pageName}/>
          {payments ? <PrintPaymentList payments={payments}/> : <PrintNoItem/> }
        </motion.div>
    );
};

const PrintPaymentList =({payments})=>{
    return(
    <div className="paymentList"> 
        {payments.map((item, i)=><PrintItem item={item} key={'id'+i}/>)}
    </div>
    )
}

const PrintItem =({item})=>{
    const price = item.amount ? item.amount : '';
    const date = item.date_payment ? item.date_payment.split('T')[0] : ''
    const time = item.date_payment ? item.date_payment.split('T')[1] : ''
    return(
        <div className="frame frame_bt">
            <div>
                <div className="sumInfo"><div>{price} грн</div></div>
                <div>
                    <div className="date">
                        <div>
                            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M448 64h-21.332V21.332C426.668 9.559 417.109 0 405.332 0H384c-11.777 0-21.332 9.559-21.332 21.332V64H149.332V21.332C149.332 9.559 139.777 0 128 0h-21.332C94.891 0 85.332 9.559 85.332 21.332V64H64C28.715 64 0 92.715 0 128v320c0 35.285 28.715 64 64 64h384c35.285 0 64-28.715 64-64V128c0-35.285-28.715-64-64-64zm21.332 384c0 11.754-9.578 21.332-21.332 21.332H64c-11.754 0-21.332-9.578-21.332-21.332V214.187h426.664zm0 0"
                                ></path>
                            </svg>
                        </div>
                        <div>{date}</div>
                    </div>
                    <div><span>•</span> {time}</div>
                </div>
            </div>
            <div><div>Списано</div></div>
        </div>
    )
}
const PrintNoItem=()=>{
    return(
        <div style={{padding:"20px", marginTop:"30px"}}>
            <h2 style={{textAlign:"center"}}>У Вас не ще на має платежів</h2>
        </div>
    )
}
export default Payment;