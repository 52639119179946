import { compose } from "../js/function";

const getLocalStorage =(name)=>{
    const getString =(name)=> window.localStorage[name]
    const getJson =(str)=> str ? JSON.parse(str) : null
    const getData =(name)=>{
        try{
            return compose(getString, getJson)(name)
        }catch(err){
            console.log('getLocalStorage',err)
            return null
        } 
    }
    return getData(name)
}
const delLocalStorage =(name)=>  window.localStorage[name] ? window.localStorage.removeItem([name]):null


const getUserInfo =()=>getLocalStorage('user_info')
export const getToken =()=> getUserInfo() ? getUserInfo().token ?  getUserInfo().token : null : null
export const setToken = (token)=>{
    const user_info ={token:token}
    window.localStorage.user_info = JSON.stringify(user_info)
}
export const delToken =()=>delLocalStorage('user_info')

export const Logout =()=>{
    delToken()
    document.location.href="/";
}

